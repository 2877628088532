import {gql} from '@apollo/client';
import {Button} from '@telia/styleguide';
import React, {FC} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import contactFragment from '../../graphql/fragment/contact.graphql';
import deleteContactMutation from '../../graphql/mutation/deleteContact.graphql';
import saveContactMutation from '../../graphql/mutation/saveContact.graphql';
import customerQuery from '../../graphql/query/customer.graphql';

import * as AppRoutes from '../../appRoutes';
import {useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Channel, Contact, Customer, ID} from '../../model';
import {CUSTOMER_USERS_MANAGE, TELIA_USERS_MANAGE} from '../../permissions';
import Loading from '../Loading';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import {FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('Contact', 'INFO');

interface ContactFcProps {
  channels: Channel[];
}

const customerQueryGql = gql(customerQuery);
export const ContactFc: FC<ContactFcProps> = (props) => {
  const {contactId, customerId} = useParams<{contactId: ID; customerId: ID}>() as {contactId: ID; customerId: ID};
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const formStateOptions = useApolloCacheEntity({
    entityId: contactId,
    fragment: contactFragment,
    newEntity: {},
  });
  const formState = useFormState(formStateOptions);
  const {isEditing, entity, findError, onEdit, onCancel, onChange, initialEntity} = formState;
  const {hasAnyPermission} = useUser();
  const {showModal} = useModal();

  const saveContact = useMutationWrap<{saveContact: Contact}, {contact: Contact; customerId: ID}>(
    gql(saveContactMutation)
  );
  const onSave = () => {
    const contact = formState.entityAs<Contact>();
    log.debug('onSave', {contact, customerId});

    saveContact({
      loadingText: 'Saving contact...',
      successText: 'Contact saved',
      variables: {
        contact: {
          ...contact,
        },
        customerId: customerId,
      },
      update: (proxy, {data}) => {
        const {saveContact} = data || {};
        log.debug('saveContactMutation update', saveContact);
        if (saveContact && !formState.entity.id) {
          //  create new contact
          const data = proxy.readQuery<{customer: Customer}, {customerId: ID}>({
            query: customerQueryGql,
            variables: {customerId},
          });
          data?.customer?.contacts?.push(saveContact);
          log.debug('data', data);
          proxy.writeQuery({query: customerQueryGql, data});

          navigate(
            formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTACT__customerId_contactId, customerId, saveContact.id),
            {replace: true}
          );
        }
      },
    }).then(({data}) => {
      const {saveContact} = data || {saveContact: undefined};
      log.debug('saveContact resolved', saveContact);
      saveContact && formState.onSaved(asEntity(saveContact));
      return saveContact;
    });
  };

  const deleteContact = useMutationWrap<{deleteContact: ID}, {contactId: ID; customerId: ID}>(
    gql(deleteContactMutation)
  );
  const onConfirmDelete = () => {
    log.debug('onConfirmDelete', {contactId});
    deleteContact({
      loadingText: 'Deleting contact...',
      successText: 'Contact deleted',
      variables: {
        contactId,
        customerId,
      },
      update: (proxy, {data}) => {
        const {deleteContact: contactId} = data || {};
        log.debug('deleteContactMutation update', contactId);
        const customerData =
          proxy.readQuery<{customer: Customer}, {customerId: ID}>({
            query: customerQueryGql,
            variables: {customerId},
          }) || undefined;
        if (customerData) {
          customerData.customer.contacts = customerData.customer.contacts?.filter(
            (contact: Contact) => contact.id !== contactId
          );
          proxy.writeQuery({query: customerQueryGql, data: customerData});
        }
      },
    }).then(({data}) => {
      const {deleteContact} = data || {deleteContact: undefined};
      log.debug('deleteContact resolved', deleteContact);
      navigate(formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTACTS__customerId, customerId), {replace: true});
    });
  };

  const onDelete = () => {
    log.debug('onDelete');
    showModal({
      title: 'Delete communication contact',
      content: <p>{`Are you sure you want to delete communication contact '${entity.name}'?`}</p>,
      confirmType: Button.kinds.negative,
      confirmText: 'Delete',
      onConfirm: onConfirmDelete,
    });
  };

  log.debug('render', {formState, contactId});
  const channelOptions = props.channels || [];
  return (
    <React.Fragment>
      <PageSubtitle subtitle="Communication contact" />

      {!entity ? (
        <Loading />
      ) : (
        <Form>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'name'} label="Name" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'email'} label="Email" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'phone'} label="Mobile" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'channels'}
                label="Contact type"
                options={channelOptions}
                type={FieldTypes.multi}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'comment'}
                label="Comment"
                type={FieldTypes.textarea}
              />
            </FormColumn>
          </FormRow>

          {!isEditing ? (
            <div>
              {hasAnyPermission(TELIA_USERS_MANAGE, CUSTOMER_USERS_MANAGE) && (
                <React.Fragment>
                  <Button text={'Edit'} onClick={onEdit} />
                  <Button text={'Delete'} onClick={onDelete} kind={Button.kinds.negative} />
                </React.Fragment>
              )}
              <Link to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTACTS__customerId, customerId)}>
                <Button text={'Back to Contacts'} kind={Button.kinds.cancel} />
              </Link>
            </div>
          ) : (
            <div>
              <Button text={'Save'} onClick={onSave} kind={Button.kinds.primary} />
              <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
            </div>
          )}
        </Form>
      )}
    </React.Fragment>
  );
};

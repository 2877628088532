import {gql, useMutation} from '@apollo/client';
import {format} from '@telia/cpa-web-common';
import {CustomerDocument} from '@telia/cpa-web-common/dist/model';
import {TagObject} from '@telia/cpa-web-common/dist/model';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import customerDocumentFragment from '../../graphql/fragment/customerDocument.graphql';
import getSignedDownloadUrl from '../../graphql/mutation/getSignedDownloadUrl.graphql';
import getSignedUploadUrl from '../../graphql/mutation/getSignedUploadUrl.graphql';
import saveCustomerDocument from '../../graphql/mutation/saveCustomerDocument.graphql';

import './Document.scss';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {asEntity, useFormState} from '../../hooks/useFormState';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {getLog} from '../../log';
import {Dict, ID} from '../../model';
import {getBucket} from '../../utils/host';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import {FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('Document', 'INFO');

export const Document: FC = (_props) => {
  const {customerId, documentId} = useParams() as Dict<ID>;
  const {getCustomerOverview} = useCustomerOverviews();
  const {brandId} = getCustomerOverview(customerId) || {};
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const [generateSignedUploadUrl] = useMutation(gql(getSignedUploadUrl));
  const [generateSignedDownloadUrl] = useMutation(gql(getSignedDownloadUrl));
  const saveDocument = useMutationWrap<
    {saveCustomerDocument: CustomerDocument},
    {
      bucket: string;
      customerId: ID;
      document: CustomerDocument;
      file: File | null;
      fileKey: string | null;
      tags: TagObject[];
    }
  >(gql(saveCustomerDocument));

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);

  const formFragmentOptions: UseApolloCacheEntityProps = {
    fragment: customerDocumentFragment,
    entityId: documentId,
    newEntity: {
      id: '',
      name: '',
      description: '',
      date: '',
      version: '',
      uploader: '',
      active: true,
    },
  };

  const formStateOptions = useApolloCacheEntity(formFragmentOptions);
  const formState = useFormState(formStateOptions);

  const {entityAs, entity, isEditing, onEdit, onCancel} = formState;

  const onSave = async () => {
    let fileKey: string | null = null;
    const bucket = getBucket();

    const tags = [
      {key: 'title', value: entity.name?.toString() || ''},
      {key: 'description', value: entity.description?.toString() || ''},
      {key: 'date', value: entity.date?.toString() || ''},
      {key: 'uploader', value: entity.uploader?.toString() || ''},
      {key: 'active', value: entity.active ? 'true' : 'false'},
    ];

    try {
      if (uploadedFile) {
        const {data: signedUrlData} = await generateSignedUploadUrl({
          variables: {
            bucket,
            brandId,
            customerId,
            fileName: uploadedFile.name,
            fileType: uploadedFile.type,
            fileSize: uploadedFile.size,
            tags,
          },
        });

        if (!signedUrlData || !signedUrlData.getSignedUploadUrl) {
          throw new Error('Failed to generate signed URL');
        }

        const {url: signedUrl, fields} = signedUrlData.getSignedUploadUrl;
        fileKey = fields.key as string;

        const formData = new FormData();
        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value as string);
        });
        formData.append('file', uploadedFile);

        await fetch(signedUrl, {
          method: 'POST',
          body: formData,
        });
      }
    } catch (error: any) {
      if (error.response?.status) {
        alert(error.response.data.message);
      }
      return;
    }

    try {
      const {data} = await saveDocument({
        loadingText: 'Saving document...',
        successText: 'Document saved',
        variables: {bucket, customerId, document: entityAs<CustomerDocument>(), file: null, fileKey, tags},
        context: {hasUpload: true},
      });

      const {saveCustomerDocument} = data || {saveCustomerDocument: undefined};

      if (saveCustomerDocument) {
        formState.onSaved(asEntity(saveCustomerDocument));
        navigate(
          formatWithBrand(
            AppRoutes.PROVISIONING_CUSTOMER_DOCUMENT__customerId_documentId,
            customerId,
            saveCustomerDocument.id
          )
        );
      }
    } catch (error) {
      throw new Error('Failed to save the document. The operation was aborted.');
    }
  };

  const handleUploadedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const file = files[0];

    const currentTimestamp = new Date().toISOString();
    const formattedTimestamp = format.dateTime(currentTimestamp);

    formState.onChange('id')(file.name);
    formState.onChange('name')(file.name);
    formState.onChange('date')(formattedTimestamp);
    setFileUploaded(true);
    setUploadedFile(file);
  };

  const formButtons = (inPageSubtitle?: boolean) => (
    <div className={classnames({'inlineBlock marginLeft': inPageSubtitle})}>
      {!isEditing ? (
        <div>
          {<Button text={'Edit'} onClick={onEdit} />}
          {<Button text={'Download'} onClick={handleDownloadFile} />}
        </div>
      ) : (
        <div>
          <Button text={'Save'} onClick={onSave} kind={Button.kinds.primary} />
          <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
        </div>
      )}
    </div>
  );

  const handleDownloadFile = async () => {
    const bucket = getBucket();
    const fileName = entity.name;

    const {data: downloadUrlData} = await generateSignedDownloadUrl({
      variables: {
        bucket,
        brandId,
        customerId,
        fileName,
      },
    });
    if (!downloadUrlData || !downloadUrlData.getSignedDownloadUrl) {
      throw new Error('Failed to generate download URL');
    }

    const {url} = downloadUrlData.getSignedDownloadUrl;
    window.location.href = url;
  };

  return (
    <>
      <PageSubtitle>
        Document
        {formButtons(true)}
      </PageSubtitle>

      {!formState.entity ? (
        <span>No form entity info</span>
      ) : (
        <Form
          onSubmit={(e) => {
            log.debug('onSubmit');
            e.preventDefault();
          }}
        >
          <FormRow>
            <FormColumn>
              {isEditing && !fileUploaded && documentId === '_create_' ? (
                <div>
                  <label htmlFor="fileInput" style={{display: 'inline-block'}}>
                    <Button text={'Upload file'} onClick={() => document.getElementById('fileInput')?.click()} />
                  </label>

                  <input type="file" id="fileInput" className="custom-file-input" onChange={handleUploadedFile} />
                </div>
              ) : (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'name'}
                  label="Filename"
                  isEditing={false}
                  className="break-word"
                />
              )}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'description'}
                label="Description"
                isEditing={isEditing}
                className="break-word"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'date'} label="Date" isEditing={false} />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'uploader'} label="Uploader" isEditing={false} />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'version'} label="Version" isEditing={false} />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'active'}
                label="Active"
                type={FieldTypes.checkbox}
              />
            </FormColumn>
          </FormRow>
        </Form>
      )}
    </>
  );
};

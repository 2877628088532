import {gql, useQuery} from '@apollo/client';
import {period} from '@telia/cpa-web-common';
import {ActiveStatus} from '@telia/cpa-web-common/dist/period';
import {accessNumbersSortComparator, withAccessNumbersSortComparator} from '@telia/cpa-web-common/dist/sort';
import {FullWidthTable} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useMemo, useRef} from 'react';
import {Link} from 'react-router-dom';

import deleteReservedOaMutation from '../../graphql/mutation/deleteReservedOa.graphql';
import saveReservedOaMutation from '../../graphql/mutation/saveReservedOa.graphql';
import reservedOasQuery from '../../graphql/query/reservedOas.graphql';

import * as AppRoutes from '../../appRoutes';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useProductOverviews} from '../../hooks/useProductOverviews';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useSenderIdClassifier} from '../../hooks/useSenderIdClassifier';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, PolicyEffect, ReservedOa, ReservedOaInput, ReservedOaRequest, SenderId} from '../../model';
import {ALL_CUSTOMERS_READ} from '../../permissions';
import {Icon} from '../common/Icon';
import {InformationLineFc} from '../common/InformationLine';
import {PickProductByAccessNumber} from '../provisioning/PickProductByAccessNumber';
import {EditSenderIdForm} from './EditSenderIdForm';
import {ReservedOaFormFc} from './ReservedOaForm';

const log = getLog('ReservedOas', 'INFO');

interface ReservedOasFcProps {
  customerId?: ID;
  ownerProductId: ID;
  effect: PolicyEffect;
  allowAdmin?: Boolean;
}

export interface EnrichedReservedOa extends ReservedOa {
  companyName?: undefined;
  customerId?: ID;
  senderIds: SenderId[];
  allowedProducts: {productId: ID; accessNumber?: string}[] | undefined;
  accessNumber?: ID;
  activeStatus: ActiveStatus;
}

const {activeStatus} = period;

export const COMMA_SEPARATOR = ', ';
export const parseCommaSeparatedIds: (comaSeparatedIds?: ID) => ID[] = (comaSeparatedIds) =>
  comaSeparatedIds
    ?.split(COMMA_SEPARATOR)
    .map((id) => id.trim())
    .filter((id) => id.length > 0) || [];

export const ReservedOasFc: FC<ReservedOasFcProps> = ({customerId, ownerProductId, effect, allowAdmin}) => {
  const {isTelia, hasPermission, currentUserBrand} = useUser();
  const {getCompanyNameByProductId, getAccessNumberByProductId, getCustomerIdByProductId} = useProductOverviews();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {brandHasCanSendProductType} = useProductTypes();
  const {getName, getIcon} = useSenderIdClassifier();
  const currentUserCountryId = currentUserBrand?.countryId;
  const {data: {reservedOas} = {}} = useQuery<{reservedOas: ReservedOa[]}, ReservedOaRequest>(gql(reservedOasQuery), {
    variables: {
      countryId: currentUserCountryId,
      ...(isTelia()
        ? {} //  in order to have more consistent cache updating under Telia users managing Reserved OAs
        : {
            ownerProductId,
            customerId,
          }),
    },
  });

  const filteredReservedOas: EnrichedReservedOa[] = useMemo(
    () =>
      (reservedOas || [])
        .filter((roa) => !effect || effect === roa.effect)
        .filter((roa) => !ownerProductId || ownerProductId === roa.ownerProductId)
        .map(({allowedProductIds, ...reservedOa}) => {
          const sortedAllowedProducts = allowedProductIds
            ?.map((productId) => ({productId, accessNumber: getAccessNumberByProductId(productId)}))
            .sort(withAccessNumbersSortComparator);
          return {
            ...reservedOa,
            senderIds: [{...reservedOa}],
            allowedProductIds,
            companyName: reservedOa.ownerProductId ? getCompanyNameByProductId(reservedOa.ownerProductId) : undefined,
            customerId: reservedOa.ownerProductId ? getCustomerIdByProductId(reservedOa.ownerProductId) : undefined,
            allowedProducts: sortedAllowedProducts,
            accessNumber: sortedAllowedProducts?.head()?.accessNumber, //  First access number for sorting
            activeStatus: activeStatus(reservedOa),
          } as EnrichedReservedOa;
        })
        .reduce((prev, reservedOa) => {
          // Find existing entry with identical list of allowed access numbers
          const existingReservedOas = prev.find(
            ({id, allowedProducts}, i) =>
              allowedProducts?.length === reservedOa.allowedProducts?.length &&
              id !== reservedOa.id &&
              allowedProducts?.every(({productId, accessNumber}) =>
                reservedOa.allowedProducts?.find(
                  (allowedProduct) =>
                    allowedProduct.accessNumber === accessNumber && allowedProduct.productId === productId
                )
              )
          );

          // If an existing entry with identical list of allowed access numbers was found, add entry
          if (existingReservedOas) {
            existingReservedOas.senderIds?.push({
              id: reservedOa.id,
              senderId: reservedOa.senderId,
              oaFormatClassifier: reservedOa.oaFormatClassifier,
            });
          } else {
            prev = [...prev, reservedOa];
          }

          return prev;
        }, [] as EnrichedReservedOa[]),
    [reservedOas, getCompanyNameByProductId]
  );

  const [sortedReservedOas, getThSortableProps] = FullWidthTable.useThSortable<EnrichedReservedOa>(
    filteredReservedOas,
    3,
    [],
    {
      // @ts-ignore: special comparators not well defined
      accessNumber: accessNumbersSortComparator,
    }
  );

  const saveReservedOa = useMutationWrap<{saveReservedOa: ReservedOa}, {reservedOa: ReservedOaInput}>(
    gql(saveReservedOaMutation)
  );
  const onSaveReservedOa = (reservedOa: ReservedOaInput) => {
    //  Define ReservedOaInput type
    log.debug('onSaveReservedOa', {reservedOa});
    return saveReservedOa({
      loadingText: 'Saving SenderID...',
      successText: 'SenderID saved',
      variables: {
        reservedOa,
      },
      update: (proxy, {data: updateData}) => {
        const {saveReservedOa} = updateData || {};
        const {reservedOas = []} =
          proxy.readQuery<{reservedOas: ReservedOa[]}>({
            query: gql(reservedOasQuery),
            variables: {countryId: currentUserCountryId},
          }) || {};
        const updateReservedOas = reservedOas.filter(({id}) => reservedOa.id !== id);
        saveReservedOa && updateReservedOas.push(saveReservedOa);
        log.debug('saveReservedOa update', {saveReservedOa, reservedOas, updateReservedOas});
        proxy.writeQuery<{reservedOas: ReservedOa[]}>({
          query: gql(reservedOasQuery),
          data: {reservedOas: updateReservedOas},
          variables: {countryId: currentUserCountryId},
        });
      },
    }).then(({data}) => {
      const {saveReservedOa} = data || {saveReservedOa: undefined};
      log.debug('saveReservedOaMutate resolved', saveReservedOa);
      return saveReservedOa;
    });
  };

  const {showModal, hideModal} = useModal();

  const senderIdModalRef = useRef<string>();

  const onSaveSenderIds = (previousSenderIds?: SenderId[]) => (reservedOa: ReservedOa, senderIds?: SenderId[]) => {
    hideModal(senderIdModalRef.current);

    const newSenderIds = senderIds?.reduce(
      (prev, curr) => (curr.id === undefined ? [...prev, curr] : prev),
      [] as SenderId[]
    );

    const deletedSenderIds = previousSenderIds?.reduce(
      (prev, curr) => (!senderIds?.find(({id}) => id === curr.id) ? [...prev, curr] : prev),
      [] as SenderId[]
    );

    newSenderIds?.forEach(({id, senderId, oaFormatClassifier}) =>
      onSaveReservedOa(
        toReservedOaInput({
          ...reservedOa, // Copy allowed senders and other common fields from parent
          id,
          senderId,
          oaFormatClassifier,
        })
      )
    );

    if (deletedSenderIds && deletedSenderIds.length > 0) {
      onDeleteReservedOa(deletedSenderIds);
    }
  };

  const onEditSenderIds = (reservedOa: ReservedOa, senderIds: SenderId[]) => {
    senderIdModalRef.current = showModal({
      title: 'Add/Remove senderIds',
      content: <EditSenderIdForm reservedOa={reservedOa} senderIds={senderIds} onSave={onSaveSenderIds(senderIds)} />,
    });
  };

  const onEditAllowedSenders = (reservedOa: ReservedOa, senderIds: SenderId[]) => {
    const {senderId, allowedProductIds} = reservedOa;
    const editAllowedSendersModalId = showModal({
      title: 'Add/Remove allowed senders for Reserved SenderID: ' + senderId,
      content: (
        <PickProductByAccessNumber
          selectedProductIds={allowedProductIds}
          onPickProductByAccessNumber={(comaSeparatedProductIds) => {
            onUpdatedAllowedProductIds(senderIds, comaSeparatedProductIds);
            hideModal(editAllowedSendersModalId);
          }}
        />
      ),
    });
    log.info('onEditReservedOa', reservedOa);
  };

  const onUpdatedAllowedProductIds = (senderIds: SenderId[], comaSeparatedProductIds: ID) => {
    senderIds.forEach(({id}) => {
      const reservedOa = reservedOas?.find((reservedOa) => reservedOa.id === id);
      if (!reservedOa) return;
      const allowedProductIds = parseCommaSeparatedIds(comaSeparatedProductIds);
      const reservedOaInput = toReservedOaInput({...reservedOa, allowedProductIds});
      log.info('onUpdatedAllowedProductIds', comaSeparatedProductIds, allowedProductIds, reservedOa, reservedOaInput);
      onSaveReservedOa(reservedOaInput).then(log.debug);
    });
  };

  const toReservedOaInput: (reservedOa: ReservedOa) => ReservedOaInput = ({
    id,
    senderId,
    effect,
    ownerProductId,
    oaFormatClassifier,
    allowedProductIds,
    ...reservedOa
  }) => ({id, senderId, effect, ownerProductId, oaFormatClassifier, allowedProductIds});

  const onDeleteReservedOa = (senderIds: SenderId[]) => {
    showModal({
      title: `Delete SenderID${senderIds.length > 1 ? 's' : ''}`,
      content: (
        <span>
          Are you sure you want to delete
          <b>
            {senderIds.map(
              ({senderId}, i, {length}) => ` '${senderId}'` + (i < length - 2 ? ',' : i === length - 2 ? ' &' : ' ') // Format array: 'abc', '123', 'efg' & '456'
            )}
          </b>
          from the reserved OAs?
        </span>
      ),
      confirmText: 'Delete',
      confirmType: 'negative',
      onConfirm: () => senderIds.forEach(({id}) => onConfirmDelete(id)),
    });
  };

  const deleteReservedOa = useMutationWrap<{}, {id: ID}>(gql(deleteReservedOaMutation));

  const onConfirmDelete = (id: ID) => {
    log.debug('onConfirmDelete');
    deleteReservedOa({
      loadingText: 'Deleting SenderID...',
      successText: 'SenderID deleted',
      variables: {id},
      update: (proxy, {}) => {
        const data = proxy.readQuery<{reservedOas: ReservedOa[]}>({
          query: gql(reservedOasQuery),
          variables: {countryId: currentUserCountryId},
        }) || {reservedOas: []};
        const reservedOas = data.reservedOas.filter((reservedOa) => reservedOa.id !== id);
        log.debug('deleteReservedOa update', {id, data, reservedOas});
        proxy.writeQuery<{reservedOas: ReservedOa[]}>({
          query: gql(reservedOasQuery),
          variables: {countryId: currentUserCountryId},
          data: {reservedOas},
        });
      },
    }).then(log.debug);
  };

  log.debug('ReservedOasFc', {reservedOas});

  const {THead, Th, Tr, ThSortable, TBody, Td} = FullWidthTable;

  return (
    <>
      {sortedReservedOas && !sortedReservedOas.isEmpty() ? (
        <FullWidthTable clickable={false}>
          <THead>
            <Tr>
              {allowAdmin && <Th className={'minWidth'} />}
              {effect === 'ALLOW' && allowAdmin && <Th className={'minWidth'} />}
              <ThSortable {...getThSortableProps('senderId')} className={'leftText'}>
                SenderID(s)
              </ThSortable>
              {!effect && <ThSortable {...getThSortableProps('effect')}>Effect</ThSortable>}
              {!ownerProductId && <ThSortable {...getThSortableProps('companyName')}>Customer</ThSortable>}
              {!ownerProductId && <ThSortable {...getThSortableProps('ownerProductId')}>Service</ThSortable>}
              {effect !== 'DENY' && (
                <>
                  <ThSortable {...getThSortableProps('accessNumber')}>Allowed short numbers</ThSortable>
                  {allowAdmin && <Th className={'minWidth'} />}
                </>
              )}
            </Tr>
          </THead>

          <TBody>
            {sortedReservedOas?.map(
              (
                {
                  companyName: reservedOaCompanyName,
                  customerId: reservedOaCustomerId,
                  allowedProducts: reservedOaAllowedProducts,
                  accessNumber,
                  activeStatus,
                  startDate,
                  endDate,
                  ...reservedOa
                },
                i
              ) => (
                <Tr key={i}>
                  {allowAdmin && (
                    <Td className={'minWidth'}>
                      <Icon
                        icon={'trash'}
                        className={'redText pointer rem1'}
                        onClick={() => onDeleteReservedOa(reservedOa.senderIds)}
                      />
                    </Td>
                  )}
                  {reservedOa.effect === 'ALLOW' && (
                    <Td className={'minWidth'}>
                      <Icon
                        icon="edit"
                        className={'blueText pointer rem1 marginRight'}
                        onClick={() => onEditSenderIds(reservedOa, reservedOa.senderIds)}
                      />
                    </Td>
                  )}
                  <Td style={{textAlign: 'left', display: 'flex', flexWrap: 'wrap'}}>
                    {(reservedOa.senderIds?.length ? reservedOa.senderIds : [{...reservedOa}]).map(
                      ({senderId, oaFormatClassifier}, i, senderIds) => (
                        <div style={{whiteSpace: 'nowrap'}}>
                          {oaFormatClassifier && (
                            <Icon
                              className={'rem1_25'}
                              icon={getIcon(oaFormatClassifier)}
                              info={getName(oaFormatClassifier)}
                            />
                          )}
                          {senderId}
                          {i < senderIds.length - 1 && <span className="marginRight">,</span>}
                        </div>
                      )
                    )}
                  </Td>
                  {!effect && (
                    <Td className={classnames(reservedOa.effect !== 'ALLOW' && 'redText')}>
                      {reservedOa.effect === 'ALLOW' ? 'Allow' : 'Block'}
                    </Td>
                  )}
                  {!ownerProductId && (
                    <Td className={classnames({disabled: !reservedOa.ownerProductId})}>
                      {reservedOa.ownerProductId ? (
                        <Link to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER__customerId, reservedOaCustomerId!)}>
                          {reservedOaCompanyName || '???'}
                        </Link>
                      ) : (
                        'System'
                      )}
                    </Td>
                  )}
                  {!ownerProductId && (
                    <Td className={classnames({disabled: !reservedOa.ownerProductId})}>
                      {reservedOa.ownerProductId ? (
                        <Link
                          to={formatWithBrand(AppRoutes.PROVISIONING_SERVICE__productId, reservedOa.ownerProductId)}
                        >
                          {reservedOa.ownerProductId}
                        </Link>
                      ) : (
                        'System'
                      )}
                    </Td>
                  )}
                  {effect !== 'DENY' && (
                    <Td className={classnames({disabled: !reservedOaAllowedProducts?.length})}>
                      {reservedOaAllowedProducts?.map(({productId, accessNumber}, index, all) => (
                        <React.Fragment key={index}>
                          {hasPermission(ALL_CUSTOMERS_READ) ? (
                            <Link to={formatWithBrand(AppRoutes.PROVISIONING_SERVICE__productId, productId)}>
                              {accessNumber || '???'}
                            </Link>
                          ) : (
                            accessNumber || '???'
                          )}
                          {index < all.length - 1 && <span>, </span>}
                        </React.Fragment>
                      )) || 'na'}
                    </Td>
                  )}
                  {allowAdmin &&
                    effect === 'ALLOW' &&
                    currentUserBrand?.id &&
                    brandHasCanSendProductType(currentUserBrand?.id) && (
                      <Td className={'minWidth'}>
                        <Icon
                          icon="edit"
                          className={'blueText pointer rem1'}
                          onClick={() => onEditAllowedSenders(reservedOa, reservedOa.senderIds)}
                        />
                      </Td>
                    )}
                </Tr>
              )
            )}
          </TBody>
        </FullWidthTable>
      ) : (
        <InformationLineFc>There are no {effect === 'DENY' ? 'blocked' : 'reserved'} SenderIDs</InformationLineFc>
      )}

      {allowAdmin && (
        <ReservedOaFormFc ownerProductId={ownerProductId} effect={effect} onSaveReservedOa={onSaveReservedOa} />
      )}
    </>
  );
};

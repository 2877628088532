import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {SettlementFc} from './common/Settlement';
import {getLog} from '../../log';
import {ID} from '../../model';

const log = getLog('SettlementPrint');

export const SettlementPrintFc: FC = () => {
  const {customerId, settlementId} = useParams<{customerId: ID; settlementId: ID}>() as {
    customerId: ID;
    settlementId: ID;
  };
  log.debug('render', {customerId, settlementId});
  return <SettlementFc settlementId={settlementId} customerId={customerId} pdf={true} />;
};

import {Button} from '@telia/styleguide';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import {version} from '../../../../../package.json';
import * as AppRoutes from '../../appRoutes';
import {useMfaConfig} from '../../hooks/useMfaConfig';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ROLES_ASSUME} from '../../permissions';
import Loading from '../Loading';
import {InformationLineFc} from '../common/InformationLine';
import Page from '../common/Page';
import PageTitle from '../common/PageTitle';
import {ImpersonateUserFc} from './ImpersonateUser';
import {LoginInfoFc} from './LoginInfo';
import {MfaInfo} from './mfa/MfaInfo';

const log = getLog('MyPage');

export const MyPageFc: FC = () => {
  const {loadingUser, user, hasRealUserPermission} = useUser();
  const {isMfaEnabled} = useMfaConfig();

  log.debug('render', {loadingUser, user, isMfaEnabled});

  return (
    <Page>
      <PageTitle title="Login Info" />
      {loadingUser ? (
        <Loading />
      ) : !user ? (
        <InformationLineFc
          description="The user has logged out successfully."
          buttons={
            <Link to={AppRoutes.LOGIN}>
              <Button text={'Go to LOGIN page'} />
            </Link>
          }
        />
      ) : (
        <>
          <LoginInfoFc />
          {isMfaEnabled && !user?.isSSOUser && <MfaInfo />}
          {hasRealUserPermission(ROLES_ASSUME) && <ImpersonateUserFc />}
        </>
      )}
      <p className="marginTop greyText">Version {version}</p>
    </Page>
  );
};

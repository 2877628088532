import {List} from '@telia/styleguide';
import React, {FC} from 'react';

import * as AppRoutes from '../appRoutes';
import {useUser} from '../hooks/useUser';
import {getLog} from '../log';
import {CUSTOMER_STATISTICS_REPORT} from '../permissions';
import {ListFc} from './common/List';
import PageTitle from './common/PageTitle';

const log = getLog('Home', 'INFO');

export const HomeFc: FC = (_) => {
  const {user, hasPermission, isTelia, isCustomer} = useUser();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  log.debug('render', {user});

  const loginGuardedLink = (link: string) => (isCustomer() && user?.customerId ? link : AppRoutes.LOGIN);

  return (
    <div>
      <PageTitle title="Telia Bulk Messaging Web" />

      <p>Welcome to the Telia Bulk Messaging Web for customers using Telia Bulk Messaging services.</p>

      {isTelia() && (
        <>
          <p>Here, Telia users of the Bulk Messaging Web can:</p>
          <ListFc>
            <List.Item>
              <List.Link href={formatWithBrand(AppRoutes.PROVISIONING)}>Administrate all Customers</List.Link>
            </List.Item>
            <List.Item>
              <List.Link href={formatWithBrand(AppRoutes.SETTLEMENT)}>See settlement (invoice) details</List.Link>
            </List.Item>
            <List.Item>
              <List.Link href={formatWithBrand(AppRoutes.REPORT_STATISTICS)}>Create traffic reports</List.Link>
            </List.Item>
            <List.Item>
              <List.Link href={formatWithBrand(AppRoutes.MANAGEMENT_USERS)}>
                Manage Telia Bulk Messaging Web Telia users
              </List.Link>
            </List.Item>
            <List.Item>
              <List.Link href={AppRoutes.DOCUMENTS}>Access documentation</List.Link>
            </List.Item>
          </ListFc>
        </>
      )}
      {(!user || (isCustomer() && user.customerId)) && (
        <>
          <p>Here, Bulk Messaging Web customers can:</p>
          <ListFc>
            <List.Item>
              <List.Link
                href={loginGuardedLink(
                  formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SETTLEMENTS__customerId, user?.customerId || '')
                )}
              >
                See settlement (invoice) details
              </List.Link>
            </List.Item>
            {hasPermission(CUSTOMER_STATISTICS_REPORT) && (
              <List.Item>
                <List.Link href={formatWithBrand(AppRoutes.REPORT_STATISTICS)}>Create traffic reports</List.Link>
              </List.Item>
            )}
            <List.Item>
              <List.Link
                href={loginGuardedLink(
                  formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SERVICES__customerId, user?.customerId || '')
                )}
              >
                Manage services
              </List.Link>
            </List.Item>
            <List.Item>
              <List.Link
                href={loginGuardedLink(
                  formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_USERS__customerId, user?.customerId || '')
                )}
              >
                Manage Telia Bulk Messaging Web users
              </List.Link>
            </List.Item>
            <List.Item>
              <List.Link
                href={loginGuardedLink(
                  formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTACTS__customerId, user?.customerId || '')
                )}
              >
                Manage communication contacts
              </List.Link>
            </List.Item>
            <List.Item>
              <List.Link href={AppRoutes.DOCUMENTS}>
                Access documentation {!user ? '(no LOGIN required)' : ''}
              </List.Link>
            </List.Item>
          </ListFc>
        </>
      )}
      <p>
        If you have any questions regarding this portal, please contact{' '}
        <a className="link" href="mailto:cpa-support@telia.no">
          cpa-support@telia.no
        </a>
      </p>
    </div>
  );
};

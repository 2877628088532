import React, {FC} from 'react';

import * as AppRoutes from '../../appRoutes';
import {getLog} from '../../log';
import {User} from '../../model';
import InformationLineFc from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';

const log = getLog('PendingUsers', 'INFO');

interface PendingUsersProps {
  users?: User[];
}

export const PendingUsers: FC<PendingUsersProps> = (props) => {
  const {users = []} = props;
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  log.debug('props', props);

  return (
    <Page>
      <PageSubtitle subtitle="Pending Users" />
      <p>
        List of people have applied for access to Telia Bulk Messaging Web. Telia admins may reject or approve access
        for these users.
      </p>
      {users.length === 0 ? (
        <InformationLineFc>No pending users</InformationLineFc>
      ) : (
        <List id="pendingUsersList">
          {users.map((user) => (
            <ListItem key={user.id} link={formatWithBrand(AppRoutes.MANAGEMENT_PENDING_USER__userId, user.id)}>
              {user.firstName || user.lastName ? (
                <div className="bold">
                  {user.firstName} {user.lastName}
                </div>
              ) : (
                <div className="bold missing">no name</div>
              )}
              <div>
                <span className="labelPrefix">Email </span> {user.email}
              </div>
              <div>
                <span className="labelPrefix">Reason </span> {user.description}
              </div>
            </ListItem>
          ))}
        </List>
      )}
    </Page>
  );
};

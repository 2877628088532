import React, {FC} from 'react';
import PageSubtitle from '../common/PageSubtitle';
import Loading from '../Loading';
import {getLog} from '../../log';
import {useServiceCategories} from '../../hooks/useServiceCategories';

const log = getLog('ServiceCategories', 'INFO');

export const ServiceCategoriesFc: FC = (props) => {
  const {loading, serviceCategories} = useServiceCategories();

  log.debug('render', props);
  return (
    <>
      <PageSubtitle subtitle="Service Categories" />

      {loading && <Loading />}

      {serviceCategories && !serviceCategories.isEmpty() && (
        <table id="serviceCategoryTable">
          <tbody>
            {serviceCategories.map((serviceCategory) => (
              <React.Fragment key={'React.Fragment.serviceCategory-' + serviceCategory.id}>
                <tr key={'serviceCategory-' + serviceCategory.id}>
                  <td className={'paddingTop'}>
                    <span className={'bold'}>{serviceCategory.id}</span>
                  </td>
                  <td className={'paddingTop'} colSpan={3}>
                    <span className={'bold'}>{serviceCategory.name}</span>
                  </td>
                </tr>
                {serviceCategory.types?.map((categoryType) => (
                  <tr key={`serviceCategoryType-${categoryType.id}`}>
                    <td></td>
                    <td>{categoryType.typeId}</td>
                    <td>{categoryType.name}</td>
                    <td>"{categoryType.invoiceText}"</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

query accessNumberHistory {
  accessNumberHistory {
    accessNumber
    accessNumberRange {
      from
      to
      count
    }
    productHistory {
      id
      customerId
      contractId
      startDate
      endDate
    }
  }
}

import {gql} from '@apollo/client';
import {period, range} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useMemo} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import util from 'util';

import productFragment from '../../graphql/fragment/product.graphql';
import saveProductMutation from '../../graphql/mutation/saveProduct.graphql';
import customerQuery from '../../graphql/query/customer.graphql';
import customerOverviewsQuery from '../../graphql/query/customerOverviews.graphql';
import reservedOasQuery from '../../graphql/query/reservedOas.graphql';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {Entity, asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {
  Contract,
  Customer,
  CustomerOverview,
  ID,
  LocalizedBranded,
  Product,
  ReservedOa,
  ReservedOaOverview,
} from '../../model';
import {CUSTOMER_PRODUCTS_MANAGE, CUSTOMER_TECHNICAL_MANAGE} from '../../permissions';
import {isProd} from '../../utils/host';
import Loading from '../Loading';
import {CountryAndBrandFormRowFc} from '../common/CountryAndBrandFormRow';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';
import {PickAccessNumber} from './PickAccessNumber';
import {ProvisionReservedOasFc} from './ProvisionReservedOas';
import {ServiceTechnical} from './ServiceTechnical';
import {ServiceTechnical as ServiceTechnicalLegacy} from './ServiceTechnicalLegacy';

const log = getLog('Service', 'INFO');
const {activeStatus, isValid} = period;
const customerQueryGql = gql(customerQuery);
const customerOverviewQueryGql = gql(customerOverviewsQuery);

type ServiceProps = LocalizedBranded;

export const Service: FC<ServiceProps> = (props) => {
  const {contractId, customerId, productId} = useParams<{contractId: ID; customerId: ID; productId: ID}>() as {
    contractId: ID;
    customerId: ID;
    productId: ID;
  };
  const {showModal, hideModal} = useModal();
  const navigate = useNavigate();
  const {
    loading,
    getBrandProductTypes,
    getEstablishFee,
    getMonthlyFee,
    getAccessNumberDisplayName,
    getDefaultQoSMessagePriority,
    getDefaultQoSMaxSmsPerSecond,
    usesAccessNumberRange,
    usesAccessNumber,
    usesPrefixNumber,
    usesRbmAgentOwner,
    usesProductName,
    hasProductTechnical,
    canReserveOa,
    canSend,
  } = useProductTypes();

  const formFragmentOptions: UseApolloCacheEntityProps = {
    fragment: productFragment,
    entityId: productId,
    newEntity: {
      countryId: props.countryId,
      brandId: props.brandId,
      customerId,
      contractId,
      qosProfile: {
        enabled: true,
      },
    },
  };
  const formStateOptions = useApolloCacheEntity(formFragmentOptions);
  const formState = useFormState(formStateOptions);
  const {initialEntity, entity, isEditing, onCancel, onChange, subEntityAt, entityAs} = formState;

  const productTypeId = entity.productType as ID;
  const countryId = subEntityAt<ID>('countryId');
  const brandId = subEntityAt<ID>('brandId');
  const brandsProductTypes = useMemo(() => getBrandProductTypes(brandId), [getBrandProductTypes, brandId]);
  const {hasPermission, currentUserBrand} = useUser();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const saveProduct = useMutationWrap<{saveProduct: Product}, {customerId: ID; contractId: ID; product: Product}>(
    gql(saveProductMutation)
  );

  const getIsProductTechnicalUpdated = () => {
    const isTeliaTestingAccountEnabled = customerId === '1001';
    const isBrandEnabled = currentUserBrand?.id === 'TELIA_SE';

    return !isProd() || isTeliaTestingAccountEnabled || isBrandEnabled;
  };

  const onSave = () => {
    const {endDate: finalEndDate} = entityAs<Product>();
    const initialEndDate = initialEntity.endDate;
    if (initialEndDate === finalEndDate) {
      onConfirmSave();
    } else {
      showModal({
        title: 'Update Service End Date',
        content: (
          <>
            <p>Are you sure you want to update the service's end date?</p>
            <p> This service will NOT be able to send messages after the end date has passed.</p>
          </>
        ),
        typeToConfirmText: 'update',
        confirmText: 'Update',
        confirmType: 'primary',
        onConfirm: () => onConfirmSave(),
      });
    }
  };

  const onConfirmSave = () => {
    const product = formState.entityAs<Product>();
    delete product.inheritsEndDate;

    if (canSend(productTypeId) && product.qosProfile) {
      if (!Number.isInteger(product.qosProfile?.messagePriority)) {
        product.qosProfile.messagePriority = getDefaultQoSMessagePriority(productTypeId);
      }
      if (!Number.isInteger(product.qosProfile?.maxSmsPerSecond)) {
        product.qosProfile.maxSmsPerSecond = getDefaultQoSMaxSmsPerSecond(productTypeId);
      }
    }

    log.debug('onSave', {customerId, contractId, product});
    saveProduct({
      loadingText: 'Saving service...',
      successText: 'Service saved',
      variables: {customerId, contractId, product},
      update(proxy, {data}) {
        const {saveProduct} = data || {};
        log.debug('saveProduct update', saveProduct);
        if (!initialEntity.id) {
          log.debug('saveProduct update. New product created', saveProduct);
          try {
            const data = proxy.readQuery<{customer: Customer}, {customerId: ID}>({
              query: customerQueryGql,
              variables: {customerId},
            });
            log.debug('saveProduct update. Customer query data', util.inspect(data));
            if (data) {
              const contracts = data.customer?.contracts?.map((c: Contract) =>
                c.id != contractId ? c : {...c, products: [...c.products, {...saveProduct!}]}
              );
              proxy.writeQuery({query: customerQueryGql, data: {customer: {...data.customer, contracts}}});
            }
          } catch (e) {
            log.warn('Customer Apollo cache not created yet');
          }
          saveProduct &&
            navigate(
              //  TODO: replace in useMutationWrap() checking for __CREATE__ id ?
              formatWithBrand(
                AppRoutes.PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId,
                customerId,
                contractId,
                saveProduct.id
              ),
              {replace: true}
            );
        } else {
          const {reservedOas = []} =
            proxy.readQuery<{reservedOas: ReservedOa[]}>({
              query: gql(reservedOasQuery),
              variables: {countryId: currentUserBrand?.countryId},
            }) || {};
          const updateReservedOas = reservedOas.map((reservedOa) =>
            reservedOa.ownerProductId === product.id
              ? {...reservedOa, startDate: product.startDate, endDate: product.endDate}
              : reservedOa
          );
          log.debug('saveReservedOa update', {reservedOas, updateReservedOas});
          proxy.writeQuery<{reservedOas: ReservedOa[]}>({
            query: gql(reservedOasQuery),
            data: {reservedOas: updateReservedOas},
            variables: {countryId: currentUserBrand?.countryId},
          });

          const {customerOverviews = []} =
            proxy.readQuery<{customerOverviews: CustomerOverview[]}, {}>({
              query: customerOverviewQueryGql,
            }) || {};
          log.debug('saveProduct update. CustomerOverview query data', util.inspect(customerOverviews));
          const updateCustomerOverviews = customerOverviews.map((customerOverview) =>
            customerOverview.id === saveProduct?.customerId
              ? {
                  ...customerOverview,
                  productOverviews: customerOverview.productOverviews.map((productOverview) =>
                    productOverview.productId === saveProduct.id && productOverview.productOverviewType === 'reservedOa'
                      ? ({
                          ...productOverview,
                          productName: saveProduct.name,
                        } as ReservedOaOverview)
                      : productOverview
                  ),
                }
              : customerOverview
          );
          proxy.writeQuery({query: customerOverviewQueryGql, data: {customerOverviews: updateCustomerOverviews}});
        }
      },
    }).then(({data}) => {
      const {saveProduct} = data || {saveProduct: undefined};
      log.info('saveProduct resolved', saveProduct);
      saveProduct && formState.onSaved(asEntity(saveProduct));
    });
  };

  const onEdit = () => {
    const {entity, onChange, onEdit} = formState;
    if (entity.inheritsEndDate) {
      onChange('inheritsEndDate')(false);
      onChange('endDate')(null);
    }
    onEdit();
  };

  //  Access Number pick
  let modalId: ID;
  const onPickButtonClick = (accessNumberField: ID) => () => {
    const config = {
      title: `Pick access number`,
      content: (
        <PickAccessNumber
          productType={productTypeId}
          productId={productId}
          rangeCount={(formState.entity.accessNumberRange as Entity)?.count as number}
          startDate={formState.entity.startDate as string}
          endDate={formState.entity.endDate as string}
          onPickAccessNumber={onPickAccessNumber(accessNumberField)}
        />
      ),
    };
    modalId = showModal(config);
  };

  const onPickAccessNumber = (accessNumberField: ID) => (accessNumber: ID) => {
    formState.onChange(accessNumberField)(accessNumber);
    if (accessNumberField === 'accessNumberRange.from') {
      const count = (entity.accessNumberRange as Entity).count as number;
      updateAccessNumberRange(accessNumber, count);
    }
    hideModal(modalId);
  };

  const updateAccessNumberRange = (from?: ID, count?: number) => {
    const accessNumberRange = {from, to: !from || !count ? undefined : range.rangeTo(from, count), count};
    log.debug('updateAccessNumberRange ', accessNumberRange);
    formState.onChange('accessNumberRange')(accessNumberRange as Entity);
  };

  log.debug('render', {
    countryId,
    brandId,
    contractId,
    customerId,
    productId,
    brandsProductTypes,
    formFragmentOptions,
    formStateOptions,
    formState,
  });
  const formButtons = (inPageSubtitle: boolean) => (
    <div className={classnames({'inlineBlock marginLeft': inPageSubtitle})}>
      {!isEditing ? (
        <div>
          {hasPermission(CUSTOMER_PRODUCTS_MANAGE) && <Button text={'Edit'} className="" onClick={onEdit} />}
          <Link
            to={formatWithBrand(
              AppRoutes.PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId,
              customerId,
              contractId
            )}
          >
            <Button text={'Go to contract'} kind={Button.kinds.cancel} />
          </Link>
        </div>
      ) : (
        <div>
          <Button text={'Save'} className="" onClick={onSave} kind={Button.kinds.primary} />
          <Button text={'Cancel'} className="" onClick={onCancel} kind={Button.kinds.cancel} />
        </div>
      )}
    </div>
  );

  const resetProductTypeSpecificFields = () => {
    onChange('accessNumber')(null);
    onChange('accessNumberRange')(null);
    onChange('rbmAgentOwner')(null);
  };

  const productTypeField = (
    <FieldWithFormState
      formState={formState}
      entityFieldId={'productType'}
      label="Service Type"
      type={FieldTypes.select}
      options={brandsProductTypes}
      tip={
        <ul>
          {brandsProductTypes?.map((productType) => (
            <li key={productType.id}>
              <span className="bold">{productType.name}</span> - {productType.description}
            </li>
          ))}
        </ul>
      }
      onChangeAlso={resetProductTypeSpecificFields}
    />
  );

  return (
    <>
      <PageSubtitle>
        Service
        {formButtons(true)}
      </PageSubtitle>

      {!entity || loading ? (
        <Loading />
      ) : (
        <>
          <CountryAndBrandFormRowFc formState={formState} canEdit={false} />

          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'id'} label="Service ID" isEditing={false} />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'contractId'}
                label="Contract ID"
                isEditing={false}
              />
            </FormColumn>
          </FormRow>
          {!entity.productType ? (
            <FormRow>
              <FormColumn>
                {productTypeField}
                <div />
              </FormColumn>
            </FormRow>
          ) : (
            <>
              <FormRow>
                <FormColumn>
                  {productTypeField}
                  <Field
                    label="Active Status"
                    value={activeStatus(entity)}
                    valueClassName={classnames(activeStatus(entity).toLowerCase() + 'Period')}
                    tip="Calculated from start and end dates"
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'startDate'}
                    label="Start date"
                    type={FieldTypes.date}
                    defaultValue={'since ever'}
                  />
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'endDate'}
                    label="End date"
                    value={entity.inheritsEndDate ? undefined : (entity.endDate as string)}
                    type={FieldTypes.date}
                    defaultValue={entity.inheritsEndDate ? (entity.endDate as string) : 'no end'}
                    tip={
                      isEditing
                        ? `Let empty to inherit contract's end date`
                        : entity.inheritsEndDate && `Inherits contract's end date`
                    }
                  />
                </FormColumn>
              </FormRow>
              {isValid(entity) && (
                <>
                  {usesAccessNumberRange(productTypeId) ? (
                    <section className={'form-group'}>
                      <SectionTitle title={'Access Number Range'} />
                      <FormRow>
                        <FormColumn>
                          <FieldWithFormState
                            formState={formState}
                            entityFieldId={'accessNumberRange.count'}
                            label={'Count'}
                            type={FieldTypes.number}
                            tip={isEditing && !isValid(entity) && `Disabled because dates are ${activeStatus(entity)}.`}
                            isDisabled={!isValid(entity)}
                            onChangeAlso={(count) =>
                              updateAccessNumberRange(
                                (entity.accessNumberRange as Entity)?.from as string | undefined,
                                count as number | undefined
                              )
                            }
                          />
                          <div></div>
                        </FormColumn>
                      </FormRow>
                      <FormRow>
                        <FormColumn>
                          <FieldWithFormState
                            formState={formState}
                            entityFieldId={'accessNumberRange.from'}
                            label={'From'}
                            tip={
                              isEditing &&
                              (!isValid(entity)
                                ? `Disabled because dates are ${activeStatus(entity)}.`
                                : !(entity.accessNumberRange as Entity)?.count
                                ? `Disabled because of access number range count`
                                : undefined)
                            }
                            button={
                              isEditing && (
                                <Button
                                  text={'Pick'}
                                  className="floatRight"
                                  onClick={onPickButtonClick('accessNumberRange.from')}
                                  isDisabled={!isValid(entity) || !(entity.accessNumberRange as Entity)?.count}
                                />
                              )
                            }
                            isDisabled={!isValid(entity) || !(entity.accessNumberRange as Entity)?.count}
                          />
                          <FieldWithFormState
                            formState={formState}
                            entityFieldId={'accessNumberRange.to'}
                            label={'To'}
                            isEditing={false}
                            // tip={isEditing && !isValid(entity) && `Disabled because dates are ${activeStatus(entity)}.`}
                            // button={
                            //   isEditing && (
                            //     <Button
                            //       text={'Pick'}
                            //       className="floatRight"
                            //       onClick={onPickButtonClick('accessNumberRange.to')}
                            //       isDisabled={!isValid(entity)}
                            //     />
                            //   )
                            // }
                            isDisabled={!isValid(entity) || !(entity.accessNumberRange as Entity)?.COUNT}
                          />
                        </FormColumn>
                      </FormRow>
                    </section>
                  ) : usesAccessNumber(productTypeId) ? (
                    <FormRow>
                      <FormColumn>
                        <FieldWithFormState
                          formState={formState}
                          entityFieldId={'accessNumber'}
                          label={getAccessNumberDisplayName(productTypeId)}
                          tip={isEditing && !isValid(entity) && `Disabled because dates are ${activeStatus(entity)}.`}
                          button={
                            isEditing && (
                              <Button
                                text={'Pick'}
                                className="floatRight"
                                onClick={onPickButtonClick('accessNumber')}
                                isDisabled={!isValid(entity)}
                              />
                            )
                          }
                          isDisabled={!isValid(entity)}
                        />
                        <div></div>
                      </FormColumn>
                    </FormRow>
                  ) : usesRbmAgentOwner(productTypeId) ? (
                    <FormRow>
                      <FormColumn>
                        <FieldWithFormState
                          formState={formState}
                          entityFieldId={'rbmAgentOwner'}
                          label={'RBM Agent Owner'}
                          tip={'External RBM agent owner ID'}
                          isDisabled={!isValid(entity)}
                        />
                        <div></div>
                      </FormColumn>
                    </FormRow>
                  ) : usesPrefixNumber(productTypeId) ? (
                    <FormRow>
                      <FormColumn>
                        <FieldWithFormState
                          formState={formState}
                          entityFieldId={'accessNumber'}
                          label={getAccessNumberDisplayName(productTypeId)}
                          tip={isEditing && !isValid(entity) && `Disabled because dates are ${activeStatus(entity)}.`}
                          onChangeAlso={(number: string) =>
                            formState.onChange(`accessNumber`)(number.replace(/\D/g, ''))
                          }
                          isDisabled={!isValid(entity)}
                        />
                        <div></div>
                      </FormColumn>
                    </FormRow>
                  ) : null}
                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'establishFee'}
                        label={'Establish fee' + (usesAccessNumberRange(productTypeId) ? ' per num.' : '')}
                        type={FieldTypes.number}
                        defaultValue={getEstablishFee(productTypeId)}
                        tip={
                          isEditing
                            ? 'Let EMPTY for DEFAULT establish FEE'
                            : entity.establishFee === null
                            ? 'Applying DEFAULT establish Fee'
                            : null
                        }
                      />
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'monthlyFee'}
                        label={'Monthly fee' + (usesAccessNumberRange(productTypeId) ? ' per num.' : '')}
                        type={FieldTypes.number}
                        defaultValue={getMonthlyFee(productTypeId)}
                        tip={
                          isEditing
                            ? 'Let EMPTY for DEFAULT monthly FEE'
                            : entity.monthlyFee === null
                            ? 'Applying DEFAULT monthly Fee'
                            : null
                        }
                      />
                    </FormColumn>
                  </FormRow>
                  {canSend(productTypeId) && (
                    <>
                      <FormRow>
                        <FormColumn>
                          <FieldWithFormState
                            formState={formState}
                            entityFieldId={'addressingPolicy'}
                            label="Addressing Policy"
                            type={FieldTypes.select}
                            options={['RESTRICTED', 'ALL']}
                            tip={
                              <ul>
                                <li>
                                  <span className="bold">RESTRICTED</span> - The SMS sender will always be the product's
                                  short number
                                </li>
                                <li>
                                  <span className="bold">ALL</span> - The SMS sender may be overwritten
                                </li>
                              </ul>
                            }
                          />
                        </FormColumn>
                      </FormRow>

                      <section className={'form-group'}>
                        <SectionTitle title={'QoS Profile'} />

                        <FormRow>
                          <FormColumn>
                            <FieldWithFormState
                              formState={formState}
                              entityFieldId={'qosProfile.enabled'}
                              label="Enabled"
                              type={FieldTypes.checkbox}
                            />
                          </FormColumn>
                        </FormRow>

                        <FormRow>
                          <FormColumn>
                            <FieldWithFormState
                              formState={formState}
                              entityFieldId={'qosProfile.messagePriority'}
                              label="Message Priority"
                              type={FieldTypes.number}
                              isDisabled={!(entity.qosProfile as Entity)?.enabled}
                              defaultValue={getDefaultQoSMessagePriority(productTypeId)}
                              tip={`Will apply default priority ${getDefaultQoSMessagePriority(
                                productTypeId
                              )} if left empty.`}
                            />
                            <FieldWithFormState
                              formState={formState}
                              entityFieldId={'qosProfile.maxSmsPerSecond'}
                              label="Max SMS/second"
                              type={FieldTypes.number}
                              isDisabled={!(entity.qosProfile as Entity)?.enabled}
                              defaultValue={getDefaultQoSMaxSmsPerSecond(productTypeId)}
                              tip={`Will apply default ${getDefaultQoSMaxSmsPerSecond(
                                productTypeId
                              )} max SMS/second if left empty.`}
                            />
                          </FormColumn>
                        </FormRow>
                      </section>
                    </>
                  )}

                  <FormRow>
                    <FormColumn>
                      {usesProductName(productTypeId) && (
                        <FieldWithFormState
                          formState={formState}
                          entityFieldId="name"
                          label="Name"
                          tip="Name of this group of Sender IDs"
                        />
                      )}
                    </FormColumn>
                  </FormRow>

                  <FormRow>
                    <FormColumn>
                      <FieldWithFormState
                        formState={formState}
                        entityFieldId={'comment'}
                        label="Comment"
                        type={FieldTypes.textarea}
                      />
                    </FormColumn>
                  </FormRow>

                  <FormRow>
                    <FormColumn>{formButtons(false)}</FormColumn>
                  </FormRow>
                </>
              )}
              {entity.id &&
                hasPermission(CUSTOMER_TECHNICAL_MANAGE) &&
                productTypeId &&
                hasProductTechnical(productTypeId) &&
                (getIsProductTechnicalUpdated() ? (
                  <ServiceTechnical productTypeId={productTypeId} />
                ) : (
                  <ServiceTechnicalLegacy productTypeId={productTypeId} />
                ))}
              {entity.id === productId && canReserveOa(productTypeId) && (
                <ProvisionReservedOasFc
                  ownerProductId={productId}
                  startDate={subEntityAt<string>('startDate')}
                  endDate={subEntityAt<string>('endDate')}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

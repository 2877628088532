import {gql, useQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import * as AppRoutes from '../../../appRoutes';
import {useUser} from '../../../hooks/useUser';
import {getLog} from '../../../log';
import {MessageChannel} from '../../../model';
import Loading from '../../Loading';
import FormColumn, {FormColumnSizeNormal} from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import {InformationLineFc} from '../../common/InformationLine';
import List from '../../common/List';
import ListItem from '../../common/ListItem';
import PageSubtitle from '../../common/PageSubtitle';

const log = getLog('MessageChannelList', 'INFO');

interface MessageChannelListProps {
  messageChannels?: MessageChannel[];
  loading: boolean;
}

export const MessageChannelListFc: FC<MessageChannelListProps> = ({messageChannels, loading}) => {
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  log.debug('render', messageChannels);

  return (
    <React.Fragment>
      <PageSubtitle subtitle="Message Channels" />
      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>
          <Link to={formatWithBrand(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS__messageChannel, AppRoutes._CREATE)}>
            <Button text={'Create Message Channel'} />
          </Link>
        </FormColumn>
      </FormRow>

      <div>
        {loading ? (
          <Loading />
        ) : messageChannels && !messageChannels.isEmpty() ? (
          <List id="messageChannelList">
            {messageChannels.map((messageChannel) => (
              <ListItem
                key={messageChannel.id}
                className={classnames('noPadding', 'marginBottomHalfRem')}
                link={formatWithBrand(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS__messageChannel, messageChannel.id)}
              >
                <div>
                  <span className={classnames('bold')}>{messageChannel.name}</span>
                  <div className={classnames('noHover', 'blackText')}>
                    <span className="greyText">{messageChannel.description ? 'Description: ' : 'No description'}</span>
                    {messageChannel.description}
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <InformationLineFc>No message channels found</InformationLineFc>
        )}

        {messageChannels && messageChannels.length > 10 && (
          <FormRow>
            <FormColumn size={FormColumnSizeNormal}>
              <Link to={formatWithBrand(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS__messageChannel, AppRoutes._CREATE)}>
                <Button text={'Create Message Channel'} />
              </Link>
            </FormColumn>
          </FormRow>
        )}
      </div>
    </React.Fragment>
  );
};

import {gql, useQuery} from '@apollo/client';
import {useMemo} from 'react';

import accessNumberHistoryQuery from '../graphql/query/accessNumberHistory.graphql';

import {getLog} from '../log';
import {AccessNumberHistory} from '../model';
import {useCustomerOverviews} from './useCustomerOverviews';
import {useUser} from './useUser';

const log = getLog('useAccessNumberHistory ', 'INFO');

interface AccessNumberHistoryQuery {
  accessNumberHistory: AccessNumberHistory[];
}

export const useAccessNumberHistory = () => {
  const {currentUserBrand} = useUser();
  const {loading: loadingCustomerOverviews, getName} = useCustomerOverviews();
  const {loading, data: {accessNumberHistory: accessNumberHistoryList} = {}} = useQuery<AccessNumberHistoryQuery>(
    gql(accessNumberHistoryQuery),
    {
      fetchPolicy: 'no-cache',
      skip: !currentUserBrand?.id,
    }
  );
  const enrichedAccessNumberHistoryList = useMemo(
    () =>
      loadingCustomerOverviews
        ? accessNumberHistoryList
        : accessNumberHistoryList?.map(({productHistory, ...accessNumberHistory}) => ({
            ...accessNumberHistory,
            productHistory: productHistory?.map((productHistory) => ({
              ...productHistory,
              customerName: getName(productHistory.customerId),
            })),
          })),
    [loadingCustomerOverviews, accessNumberHistoryList]
  );
  const hook = {loading, accessNumberHistoryList: enrichedAccessNumberHistoryList};
  log.debug(hook);
  return hook;
};

import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {getLog} from '../../log';
import {Smsc} from '../../model';
import Loading from '../Loading';
import FormColumn, {FormColumnSizeNormal} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import PageSubtitle from '../common/PageSubtitle';

const log = getLog('SmscList', 'INFO');

export interface SmscsQuery {
  smscs: Smsc[];
}

interface SmscListProps {
  smscs?: Smsc[];
  loading: boolean;
}

export const SmscListFc: FC<SmscListProps> = ({smscs, loading}) => {
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  log.debug('render', smscs);

  return (
    <React.Fragment>
      <PageSubtitle subtitle="SMSCs" />
      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>
          <Link to={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS__smsc, AppRoutes._CREATE)}>
            <Button text={'Create SMSC'} />
          </Link>
        </FormColumn>
      </FormRow>

      <div>
        {loading ? (
          <Loading />
        ) : smscs && !smscs.isEmpty() ? (
          <List id="smscList">
            {smscs.map((smsc) => (
              <ListItem
                key={smsc.id}
                className={classnames('noPadding', 'marginBottomHalfRem')}
                link={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS__smsc, smsc.id)}
              >
                <div>
                  <span className={classnames('bold')}>{smsc.name}</span>
                  <div className={classnames('noHover', 'blackText')}>
                    <span className="greyText">{smsc.description ? 'Description: ' : 'No description'}</span>
                    {smsc.description}
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <InformationLineFc>No SMSCs found</InformationLineFc>
        )}

        {smscs && smscs.length > 10 && (
          <FormRow>
            <FormColumn size={FormColumnSizeNormal}>
              <Link to={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS__smsc, AppRoutes._CREATE)}>
                <Button text={'Create Message Channel'} />
              </Link>
            </FormColumn>
          </FormRow>
        )}
      </div>
    </React.Fragment>
  );
};

import React, {useMemo} from 'react';
import {Operator} from '@telia/cpa-web-common/src/model';
import operatorsQuery from '../graphql/query/operators.graphql';
import {gql, useQuery} from '@apollo/client';
import {getLog} from '../log';

const log = getLog('useOperators', 'INFO');

interface OperatorsQuery {
  operators: Operator[];
}

type NetType = 'On net' | 'Off net' | 'Foreign';

export interface UseOperators {
  loading: boolean;
  operators: Operator[] | undefined;
  onNetCodes: number[];
  getNetType: (code: Number | undefined) => NetType;
}

export const useOperators = () => {
  const {loading, data: {operators} = {}} = useQuery<OperatorsQuery>(gql(operatorsQuery));

  const onNetCodes = useMemo(() => operators?.flatMap<number>(({codes}) => codes).sort() || [], [operators]);

  const getNetType = (code: number | undefined): NetType => {
    if (code === undefined || Number.isNaN(code)) return 'Foreign';
    if (onNetCodes?.includes(code)) return 'On net';
    return 'Off net';
  };

  const useOperatorsHook: UseOperators = {
    loading,
    operators,
    onNetCodes,
    getNetType,
  };
  log.debug('render', useOperatorsHook);
  return useOperatorsHook;
};

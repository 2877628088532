import {period} from './index';

export type ID = string;
export type WithId<T = any> = T & {
  id: ID;
};

export type ApolloObject<T> = T & {__typename?: string};

export interface IdAndName {
  id: ID;
  name: string;
}

export function isID(id: any): id is ID {
  return !!id && typeof id === 'string';
}

export function isWithId(a: any): a is WithId {
  return !!a && typeof a === 'object' && isID(a.id);
}

export type Get<T> = () => T | undefined;

export interface Dict<T> {
  [keys: string]: T;
}

export type FormattedDate = string;
export type CurrencyCode = string;

export enum UserType {
  TELIA = 'TELIA',
  CUSTOMER = 'CUSTOMER',
}

export interface Country {
  id: ID; //  'NO'
  countryCode: ID; //  '47'
  name: ID; //  'Norway'
}

export const TELIA_NO = 'TELIA_NO';

export const DK: Country = {id: 'DK', countryCode: '45', name: 'Denmark'};
export const EE: Country = {id: 'EE', countryCode: '372', name: 'Estonia'};
export const FI: Country = {id: 'FI', countryCode: '358', name: 'Finland'};
export const LT: Country = {id: 'LT', countryCode: '370', name: 'Lithuania'};
export const NO: Country = {id: 'NO', countryCode: '47', name: 'Norway'};
export const SE: Country = {id: 'SE', countryCode: '46', name: 'Sweden'};

export const allCountries = {
  DK,
  EE,
  FI,
  LT,
  NO,
  SE,
};

export interface Brand {
  id: ID; //  'TELIA_NO'
  countryId: ID; //  'NO'
  currencyCode: CurrencyCode; //  'NOK'
  name: string; //  'Telia (NO)
  organizationNumber: ID; //  '123456789'
}

export interface LocalizedBranded {
  countryId: ID; //  'NO'
  brandId: ID; //  'TELIA_NO'
}

export interface Permission {
  id: ID;
  category?: string;
  name: string;
  description?: string;
}

export interface Role {
  id: ID;
  userType: UserType;
  name: string;
  description?: string;
  permissions: ID[];
}

export enum UserAccessStatusType {
  APPLYING = 'APPLYING',
  PENDING = 'PENDING',
  GRANTED = 'GRANTED',
  REJECTED = 'REJECTED',
}

export interface MfaOptions {
  sms: {
    enabled: boolean;
    phone?: string;
  };
  app: {
    enabled: boolean;
  };
}

export interface User {
  id: ID;
  cognitoId?: string;
  firstName: string;
  lastName?: string;
  phone?: string;
  email: string;
  roles: ID[];
  permissions?: ID[];
  type: UserType;
  userAccessStatusType?: UserAccessStatusType;
  customerId?: ID;
  comment?: string;
  rolesMap?: ResourceRoles[];
  description?: string; // For SSO users requesting access
  isSSOUser?: boolean; // Only defined for currently logged in users
  mfaOptions?: MfaOptions;
  // lastLogin?: Date;
}

export type Resource = string;
export interface ResourceRoles {
  resource: Resource;
  roles: ID[];
  permissions?: ID[];
}

export interface Address {
  streetAddress?: string;
  postCode?: string;
  city?: string;
  country?: string;
}

export interface Company {
  id: ID;
  countryId: ID;
  brandId: ID;
  name: string;
  organizationNumber: string;
  businessAddress?: Address;
  billingAddress?: Address;
  differentBillingAddress: boolean;
}

export interface CompanyTechnical {
  ips: Ip[];
  cidrs: Ip[];
}

export interface Ip {
  address: string;
  description?: string;
}

export type AddressingPolicy = 'ALL' | 'RESTRICTED';

export interface ProductType {
  id: ID;
  countryId: ID;
  brandId: ID;
  name: string;
  description: string;
  canSend: boolean;
  feeModels?: FeeModel[];
  smppApiConfigDefaults?: SmppApiConfig;
}

export interface AccessNumberRange {
  from: ID;
  to: ID;
  count?: number;
}

export interface QoSProfile {
  enabled: boolean;
  maxSmsPerSecond: number;
  messagePriority: number;
}

export interface Product extends period.PeriodI {
  id: ID;
  customerId: ID;
  contractId: ID;
  countryId: ID;
  brandId: ID;
  productType: ID;
  accessNumber?: ID;
  accessNumberRange?: AccessNumberRange;
  rbmAgentOwner?: ID;
  startDate: FormattedDate;
  endDate?: FormattedDate;
  inheritsEndDate?: boolean;
  establishFee: number;
  monthlyFee: number;
  addressingPolicy: AddressingPolicy;
  name?: string;
  comment?: string;
  technical?: ProductTechnical;
  qosProfile?: QoSProfile;
}

export interface HasFeeModels {
  feeModels: FeeModel[];
}

export function hasFeeModels(obj: any): obj is HasFeeModels {
  return !!(obj as HasFeeModels)?.feeModels;
}

export interface FeeModel {
  description: string;
  startDate: FormattedDate;
  endDate?: FormattedDate;
  fees: Fee[];
}

export type FeeTypeId = ID; //TODO: enum type

export interface Fee {
  description: string;
  feeTypeId: FeeTypeId;
  fixedAmount: number;
  steps: FeeStep[];
}

export interface FeeStep {
  from: number;
  to: number;
  amount: number;
}

export interface ProductTechnical {
  id?: ID;
  features?: string;
  protocol?: EndpointProtocol;
  endpoints?: DeliveryEndpoints;
  apiCredentials?: ApiCredentials;
  smppApiConfig?: SmppApiConfig;
}

export interface ProductTechnicalInput {
  features?: string;
  protocol?: EndpointProtocol;
  endpoints?: DeliveryEndpoints;
}

export interface ApiCredentials {
  clientId: ID;
  secretLength?: number;
  secretLastUpdated?: string;
}

export interface DeliveryEndpoints {
  mo: DeliveryEndpoint;
  dr: DeliveryEndpoint;
}

export interface DeliveryEndpoint {
  endpoint: string;
  protocol?: EndpointProtocol;
  username: string;
  password: string;
}

export enum EndpointProtocol {
  SOAP = 'SOAP',
  REST = 'REST',
  SMPP = 'SMPP',
}

export interface SmppApiConfig {
  defaultCharset?: DefaultCharset;
  windowSize?: number;
  maxWindowSize?: number;
  maxSessions?: number;
  minReceiverSessionAlarmThreshold?: number;
  minTransmitterSessionAlarmThreshold?: number;
}

export enum DefaultCharset {
  GSM8 = 'GSM8',
  GSM7 = 'GSM7',
  ISO_8859_1 = 'ISO_8859_1',
  ISO_8859_15 = 'ISO_8859_15',
}

export type ContractType = 'INTERNAL' | 'EXTERNAL';

export interface Contract extends period.PeriodI {
  id: ID;
  customerId: ID;
  name?: string;
  startDate: period.FormattedDateOrNull;
  endDate: period.FormattedDateOrNull;
  type: ContractType;
  invoiceIncludeVat: boolean;
  invoiceReference?: string;
  invoiceAccountId: ID;
  comment?: string;
  products: Product[];
}

export interface Customer {
  id: ID;
  countryId: ID;
  brandId: ID;
  company: Company;
  contracts?: Contract[];
  users?: User[];
  contacts?: Contact[];
  documents?: CustomerDocument[];
  smppSession?: SmppSession[];
}

export interface CustomerOverview {
  id: ID;
  countryId: ID;
  brandId: ID;
  name: string;
  productOverviews: Overview[];
}

export type Overview =
  | AccessNumberOverview
  | AccessNumberRangeOverview
  | ReservedOaOverview
  | RbmOverview
  | GenericOverview;

export type ProductOverviewType = 'accessNumber' | 'accessNumberRange' | 'reservedOa' | 'rbm' | 'generic';

export interface GenericOverview {
  customerId: ID;
  contractId: ID;
  productId: ID;
  state: string;
  productOverviewType: ProductOverviewType;
  productTypeId: ID;
}
export interface AccessNumberOverview extends GenericOverview {
  accessNumber: ID;
}

export interface AccessNumberRangeOverview extends GenericOverview {
  accessNumberRange: AccessNumberRange;
}

export interface ReservedOaOverview extends GenericOverview {
  productName?: string;
  reservedOas?: ID[];
}

export interface RbmOverview extends GenericOverview {
  rbmAgentOwner: string;
}

export type ProductOverview = (
  | ReservedOaOverview
  | AccessNumberOverview
  | AccessNumberRangeOverview
  | RbmOverview
  | GenericOverview
) & {
  companyName: string;
};

export type SettlementState = 'PENDING' | 'READY_FOR_APPROVAL' | 'APPROVED';
export type SettlementAction = 'ACCEPT' | 'REJECT' | 'APPROVE';

export interface Settlement {
  id: ID;
  date: Date;
  state: SettlementState;
  stateAction?: SettlementStateAction;
  yearMonth: ID;
  isComplete: boolean;
  currencyCode: CurrencyCode;
  customer: SettlementCustomer;
  // ...customerMinimalFragment
  contract: SettlementContract;
  // summary: SettlementMonthTotal;
  summary: SettlementSummary;
  productTypeSummaries?: SettlementProductTypeSummary[];
  adjustments?: SettlementAdjustment[];
  productDetails?: SettlementProductDetail[];
}

export interface SettlementCompany {
  id: ID;
  countryId: ID;
  brandId: ID;
  name: string;
  organizationNumber: string;
}

export interface SettlementCustomer {
  id: ID;
  countryId: ID;
  brandId: ID;
  company: SettlementCompany;
}

export interface SettlementContract extends period.PeriodI {
  id: ID;
  name?: string;
  type: ContractType;
  invoiceIncludeVat: boolean;
  invoiceReference?: string;
  invoiceAccountId: ID;
  comment?: string;
}

export interface SettlementSummary {
  settlementTotal: number;
  adjustmentTotal: number;
  totalExclVat: number;
  vat: number;
  vatPercentage: number;
  totalInclVat: number;
}

export interface SettlementStateAction {
  action: SettlementAction;
  comment: string;
  // userId
  // userName
  // createdDate
}

export declare type SettlementProductTypeSummary = {
  productTypeId: ID;
  fees: ProductFee[];
  total: number;
};

export declare type ProductFee = {
  feeId: ProductFeeId;
  type: ProductFeeType;
  quantity: number;
  fee: number;
  total: number;
};

export type ProductFeeId =
  | 'HOME'
  | 'DOMESTIC'
  | 'FOREIGN'
  | 'BLOCKED'
  | 'RBM_BASIC_MESSAGE'
  | 'RBM_SINGLE_MESSAGE'
  | 'RBM_A2P_CONVERSATION'
  | 'RBM_AGENT'
  | 'MONTHLY'
  | 'ESTABLISHMENT';
export type ProductFeeType = 'TRAFFIC' | 'MONTHLY' | 'ESTABLISHMENT';

export interface SettlementAdjustmentFeeCategory {
  productTypeId: ID;
  feeId: ID;
}
export interface SettlementAdjustment {
  comment: string;
  price: number;
  feeCategory?: SettlementAdjustmentFeeCategory;
}

export interface SettlementProductDetail {
  productDetailId?: ID;
  productId: ID;
  productTypeId: ID;
  total: number;
  fees: ProductFee[];
}

export interface SettlementMonthTotal {
  total: number;
  productTypeSummaries: SettlementProductTypeSummary[];
  adjustmentSummary: AdjustmentSummary;
}

export interface AdjustmentSummary {
  count: number;
  total: number;
}

export interface ProductSettlementMonthTotals {
  productId: string;
  EXTERNAL: number;
  INTERNAL: number;
}

export interface SettlementMonthTotals {
  period: ID;
  isComplete: boolean;
  isApproved: boolean;
  products: ProductSettlementMonthTotals[];
}

export interface AccessNumberHistory {
  accessNumber?: ID;
  accessNumberRange?: AccessNumberRange;
  productHistory: ProductHistory[];
}

export interface ProductHistory {
  id: ID;
  customerName?: string;
  customerId: ID;
  contractId: ID;
  startDate: string;
  endDate?: string;
}

export interface AccessNumberSeries {
  id: ID;
  name?: string;
  from: ID;
  to: ID;
  comment?: string;
  productTypes: ID[];
}

export interface Channel {
  id: ID;
  name: string;
  description?: string;
}

export interface Contact {
  id: ID;
  name: string;
  phone?: string;
  email?: string;
  comment?: string;
  channels?: ID[];
}

export interface AccessNumber {
  id: string;
  enabled?: boolean;
  connection?: SmppConnection;
}

export interface SmppConnection {
  systemId?: string;
  password?: string;
  connectionCount?: number;
  connectTimeout?: number;
  bindTimeout?: number;
  requestTimeout?: number;
  windowSize?: number;
  windowWaitTimeout?: number;
  windowMonitorInterval?: number;
  enquireLinkInterval?: number;
  enquireLinkTimeout?: number;
}

export interface ReportMessageEvent {
  id?: ID;
  time: string;
  type: ID;
  status?: number;
  details?: string;
  messagePartId?: string;
  segmentCount?: number;
}

export interface ReportMessagePart {
  id: ID;
  segmentCount: number;
}

export interface ReportMessage {
  version?: ID;
  messageType: ID; // 'SMS_MT' | 'SMS_MO'
  messageId: ID;
  customerId: ID;
  apiAcceptTime: string; // apiAcceptDate
  apiNotifyTime?: string; // doneDate
  submitId?: ID;
  productTypeId?: ID;
  productId?: string;
  productQosPriority?: number;
  contractId?: string;
  accessNumber?: string; // accessNumberId
  oa?: string;
  da?: string;
  daOperator?: string;
  pricePlan?: string;
  cref?: string;
  segmentCountTotal: number;
  messageParts?: ReportMessagePart[];
  serviceCategory?: string;
  serviceType?: string;
  status?: number;
  details?: string;
  submitDurationMs?: number;
  deliverDurationMs?: number;
  totalDurationMs?: number;
  events?: ReportMessageEvent[];
}

export interface ReportMessagesResponse {
  items?: ReportMessage[];
  /**
   * Optional last evaluated key for pagination purpose. The value is an URI encoded stringified JSON object
   */
  lastEvaluatedKey?: string;
}

export interface ServiceCategory {
  id: string;
  name: string;
  types: ServiceCategoryType[];
}

export interface ServiceCategoryType {
  id: string;
  typeId: string;
  name: string;
  invoiceText: string;
}

export interface MessageChannel {
  id: ID;
  name: string;
  description?: string;
  countryId: ID;
  provider?: string;
  endpointUrl?: string;
  callbackUrl?: string;
  smppConfig?: SmppConfig;
}

export interface SmsConfig {
  direction: SmsConfigDirection;
  smppConfig: SmppConfig;
}

export type SmsConfigDirection = 'MT' | 'DR' | 'MO';

export interface SmppConfig {
  smscId: string;
  smppAccounts: SmppAccount[];
}

export interface SmppAccount {
  id: string;
  systemId?: string;
  password?: string;
  comment?: string;
  enabled?: boolean;
  smppVersion?: string;
  sessionType: SessionBindType;
  sessionCount: number;
  smppWindow: SmppWindow;
  subnumberProxy?: SubnumberProxy;
}

export interface SmppSession {
  sessionId: string;
  instanceId: string;
  productId: string;
  sessionCreatedAt: string;
  bindType: SessionBindType;
  apiEndpoint: string;
}

export interface SmppSessionsWithProductDetails {
  sessionId: string;
  instanceId: string;
  productId: string;
  sessionCreatedAt: string;
  bindType?: SessionBindType;
  apiEndpoint: string;
  customerId: string;
  apiCredentials: string;
}

export const TRANSMITTER = 'TRANSMITTER';
export const RECEIVER = 'RECEIVER';
export const TRANSCEIVER = 'TRANSCEIVER';
export type SessionBindType = typeof TRANSMITTER | typeof RECEIVER | typeof TRANSCEIVER;

export interface SmppWindow {
  size: number;
  waitTimeout?: Duration;
  monitorInterval?: Duration;
  submitOfferTimeout?: Duration;
  enquireLinkOfferTimeout?: Duration;
}

export interface SubnumberProxy {
  enabled?: boolean;
  matchExpression?: string;
  prefix?: string;
}

export type Duration = string; //  ISO 8601 duration

export interface Smsc {
  id: ID;
  name: string;
  description?: string;
  smscNodes: SmscNode[];
  timeZone: string;
  smscConnection: SmscConnection;
  healthConfig: HealthConfig;
  countryId: ID;
}

export interface HealthConfig {
  enquireLinkInterval: Duration;
  enquireLinkTimeout: Duration;
}

export interface SmscNode {
  id: ID;
  enabled?: boolean;
  host: string;
  port: number;
  name?: string;
  comment?: string;
}

export interface SmscConnection {
  connectTimeout?: Duration;
  bindTimeout?: Duration;
  requestTimeout?: Duration;
  windowMonitorInterval?: Duration;
}

export type PolicyEffect = 'ALLOW' | 'DENY';
export type ReservedOaFormatClassifier = 'ALPHANUM' | 'NUMBER' | 'SHORTCODE';

export interface SenderId {
  id: ID;
  senderId: ID;
  oaFormatClassifier?: ReservedOaFormatClassifier;
}

export interface ReservedOa {
  id: ID;
  senderId: ID;
  countryId?: ID;
  ownerProductId?: ID;
  startDate?: string;
  endDate?: string;
  effect: PolicyEffect;
  allowedProductIds?: ID[];
  oaFormatClassifier?: ReservedOaFormatClassifier;
  modifiedDate?: string;
  modifiedBy?: string;
}

export interface ReservedOaInput {
  id?: ID;
  senderId?: ID;
  effect: PolicyEffect;
  ownerProductId?: string;
  allowedProductIds?: ID[];
  oaFormatClassifier?: ReservedOaFormatClassifier;
}

export interface ReservedOaRequest {
  countryId?: ID;
  ownerProductId?: ID;
  customerId?: ID;
}

export interface HasName {
  name: string;
}
export function hasName(obj: any): obj is HasName {
  return !!(obj as HasName).name;
}

export interface HasMessage {
  message: string;
}
export function hasMessage(obj: any): obj is HasMessage {
  return !!(obj as HasMessage).message;
}

export interface Operator {
  id: ID;
  codes: number[];
}

export interface Statistic {
  accessNumber: string;
  contractId: ID;
  contractType: string;
  customerId: ID;
  customerName?: string;
  date: string;
  month?: string;
  //TODO: Remove eventType - when detailed statistics page is updated
  eventType?: string;
  //TODO: Remove nullables from messageType, status, resolution - when detailed statistics page is updated
  messageType?: string;
  status?: number;
  resolution?: string;
  messages: number;
  operatorCode: string;
  netType?: string;
  productType: ID;
  segments: number;
}

export interface CustomerDocument {
  id: string;
  name: string;
  description: string;
  date: string;
  version: string;
  uploader: string;
  active: boolean;
}

export interface TagObject {
  key: string;
  value: string;
}

export enum ResolutionId {
  Delivered = 'DELIVERED',
  Expired = 'EXPIRED',
  Rejected = 'REJECTED',
}

export interface Resolution {
  id: ResolutionId;
  name: string;
}

export const resolutions: Resolution[] = [
  {id: ResolutionId.Delivered, name: 'Delivered'},
  {id: ResolutionId.Rejected, name: 'Rejected'},
  {id: ResolutionId.Expired, name: 'Expired'},
];

export interface RequestAccessInput {
  firstName: string;
  lastName?: string;
  phone?: string;
  email: string;
  description: string;
}

export enum MfaConfig {
  ON = 'ON',
  OPTIONAL = 'OPTIONAL',
  OFF = 'OFF',
}

export interface Document {
  name: string;
  url: string;
}

export type CountriesDocuments = {
  [key in keyof typeof allCountries]: Document[];
};

export type ProductSessions = {
  productId: ID;
  customerId: ID;
  customerName: string;
  receiverCount: number;
  transmitterCount: number;
  transceiverCount: number;
  maxSessions: number;
};

import {gql, useQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import React, {FC, useState} from 'react';

import saveRoleMutation from '../../graphql/mutation/saveRole.graphql';
import permissionsQuery from '../../graphql/query/permissions.graphql';

import './Permissions.scss';

import UserTypes from '../../components/user/userTypes';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useRoles} from '../../hooks/useRoles';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, Permission, Role} from '../../model';
import {mutationInputClean} from '../../mutationClean';
import {ROLES_MANAGE} from '../../permissions';
import Loading from '../Loading';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import PageSubtitle from '../common/PageSubtitle';
import Table from '../common/Table';
import TableCell from '../common/TableCell';
import TableRow from '../common/TableRow';
import Tooltip from '../common/Tooltip';
import {Field, FieldTypes} from '../common/field';

const log = getLog('Permissions');

const categoryCompare = (category?: ID, other?: ID) => categorySorting(category) - categorySorting(other);

const categorySorting = (category?: ID) => {
  switch (category) {
    case 'CUSTOMER':
      return 1000;
    case 'TELIA':
      return 2000;
    case 'SETTLEMENT':
      return 3000;
    case 'AUTH':
      return 4000;
    default:
      return 5000;
  }
};

interface PermissionsQuery {
  permissions: Permission[];
}

export const PermissionsFc: FC = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const {hasPermission} = useUser();
  const {loading: loadingRoles, roles: rolesUnsorted, getRole} = useRoles();
  const roles = rolesUnsorted?.slice().sort((p, other) => p.id.localeCompare(other.id));
  const {loading: loadingPermissions, data: {permissions: permissionsUnsorted} = {}} = useQuery<PermissionsQuery>(
    gql(permissionsQuery)
  );
  const permissions = permissionsUnsorted
    ?.slice()
    .sort((p, other) => categoryCompare(p.category, other.category) + p.id.localeCompare(other.id));

  const togglePermission = (roleId: ID, permissionId: ID) => (value: boolean) => {
    log.debug('togglePermission', {roleId, permissionId, value});
    const oldRole = getRole(roleId);
    const role: Role = {...oldRole, permissions: oldRole?.permissions.cloneToggle(permissionId) || []} as Role;
    onSave(role);
  };

  const saveRole = useMutationWrap<{saveRole: Role}, {role: Role}>(gql(saveRoleMutation));
  const onSave = (role: Role) => {
    log.debug('onSave', {role});
    saveRole({
      loadingText: 'Saving role...',
      successText: 'Role saved',
      variables: {
        role: mutationInputClean(role),
      },
    });
  };

  log.debug('Permissions', {isEditing, roles, permissions});

  return (
    <React.Fragment>
      <PageSubtitle subtitle="Permissions" />

      <div>
        {loadingPermissions || loadingRoles ? (
          <Loading />
        ) : permissions && !permissions.isEmpty() ? (
          <Table id="permissionsTable">
            <thead>
              <TableRow type="header">
                <TableCell isHeader={true}> </TableCell>
                <TableCell isHeader={true}> </TableCell>
                <TableCell isHeader={true}> </TableCell>
                {roles.map((role) => (
                  <TableCell key={'Role' + role.id} className={'rotate-45'} isHeader={true}>
                    <div className={''}>
                      <span>{role.id}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </thead>
            <tbody>
              {permissions.map((aPermission) => (
                <TableRow key={aPermission.id}>
                  <TableCell>{aPermission.id}</TableCell>
                  <TableCell>
                    <Tooltip
                      text={aPermission.category}
                      buttonContent={aPermission.category && aPermission.category.substring(0, 1)}
                      className={`tooltip--${aPermission.category && aPermission.category.substring(0, 1)}`}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip text={aPermission.description} />
                    {/*<Icon icon={'info'} info={aPermission.description} className={'infoIcon'}/>*/}
                    {/*<div className={classnames('tooltip', {'tooltip--show': tooltips.includes(aPermission.id)})}>*/}
                    {/*<div className='tooltip__button-container'>*/}
                    {/*<button id='tooltip-button' className='tooltip__button' aria-controls='tooltip-box' aria-expanded='false'*/}
                    {/*onClick={() => this.setState({tooltips: tooltips.cloneToggle(aPermission.id)})}*/}
                    {/*onMouseOver={() => this.setState({tooltips: tooltips.cloneToggle(aPermission.id)})}*/}
                    {/*onMouseOut={() => this.setState({tooltips: tooltips.cloneToggle(aPermission.id)})}*/}
                    {/*>*/}
                    {/*i*/}
                    {/*</button>*/}
                    {/*</div>*/}
                    {/*<div className='tooltip__box' id='tooltip-box'>{aPermission.description}</div>*/}
                    {/*</div>*/}
                  </TableCell>
                  {roles?.map((role) => (
                    <TableCell key={aPermission.id + role.id} className={''}>
                      {(role.userType === UserTypes.TELIA || aPermission.category === 'CUSTOMER') && (
                        <Field
                          type={FieldTypes.checkbox}
                          value={role.permissions.includes(aPermission.id)}
                          isEditing={isEditing}
                          onChange={togglePermission(role.id, aPermission.id)}
                        />
                      )}
                      {/*<CRUD crudRole={aPermission.crudRoles.find(crudRole => crudRole.role === role)} editing={isEditing}*/}
                      {/*setPermission={this.setPermission(aPermission.id, role)}/>*/}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </Table>
        ) : (
          <InformationLineFc>No permissions found</InformationLineFc>
        )}
      </div>

      <br />
      {isEditing ? (
        <React.Fragment>
          <FormRow>
            <FormColumn>
              <div>
                <Button onClick={() => setIsEditing(false)} text="Stop editing" />
              </div>
            </FormColumn>
          </FormRow>
          <InformationLineFc description={`Changes are applied immediately!`} />
        </React.Fragment>
      ) : (
        <FormRow>
          {hasPermission(ROLES_MANAGE) && (
            <FormColumn>
              <div>
                <Button onClick={() => setIsEditing(true)} text="Edit roles" />
              </div>
            </FormColumn>
          )}
        </FormRow>
      )}
    </React.Fragment>
  );
};

import {format} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import React, {FC, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {domId} from '../../dom';
import {useModal} from '../../hooks/useModal';
import {useShowInactive} from '../../hooks/useShowInactive';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Contract, ID} from '../../model';
import {CUSTOMER_PRODUCTS_MANAGE} from '../../permissions';
import FormColumn, {FormColumnSizeNormal} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes} from '../common/field';
import {ServicesTable} from './ServicesTable';
import {filterInactiveContractsAndProducts} from './utils';

const log = getLog('Services', 'INFO');

interface ServicesProps {
  contracts?: Contract[];
}

export const Services: FC<ServicesProps> = (props) => {
  const [contractId, setContractId] = useState<ID>();
  const {hasPermission} = useUser();
  const {showInactive, showInactiveComponent} = useShowInactive();
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};
  const {showModal, hideModal} = useModal();
  const contracts = filterInactiveContractsAndProducts(props.contracts, showInactive);
  const onCreateProduct = (contractId: ID) => () => {
    log.debug('onCreateProduct for contract ' + contractId);
    navigate(
      formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_SERVICE_CREATE__customerId_contractId, customerId, contractId)
    );
  };

  const hasProducts = (contracts: Contract[]) => {
    let isContractsContained = contracts && !contracts.isEmpty();
    let isProductsContained = false;

    if (isContractsContained) {
      for (let i = 0; i < contracts.length; i++) {
        const isProductsContained = !contracts[i].products.isEmpty();

        if (isProductsContained) {
          return isProductsContained;
        }
      }
    }

    return isContractsContained && isProductsContained;
  };

  const onCreate = () => {
    !contracts || contracts.length === 0
      ? showModal({
          title: 'No contracts',
          content: <span>`In order to create a service, should at least one contract be available`</span>,
          confirmText: `Go to Contracts`,
          onConfirm: () => navigate(formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_CONTRACTS__customerId, customerId)),
        })
      : contracts.length > 1
      ? showModal({
          title: 'Multiple contracts',
          content: (
            <>
              <span>Select the corresponding service's Contract</span>
              <FormRow>
                <FormColumn>
                  <Field
                    type={FieldTypes.select}
                    isEditing={true}
                    options={contracts.map((c) => ({id: c.id, name: format.contractDisplayName(c)}))}
                    value={contractId}
                    onChange={(contractId) => {
                      log.debug('Multiple contracts, onChange contract ID', contractId);
                      contractId && onCreateProduct(contractId as ID)();
                      hideModal();
                    }}
                  />
                </FormColumn>
              </FormRow>
            </>
          ),
          onCancel: () => {
            setContractId(undefined);
          },
        })
      : onCreateProduct(contracts[0].id)();
  };

  log.debug('render', {customerId, showInactive, contractId});

  return (
    <Page>
      <PageSubtitle subtitle="Services" />

      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>{showInactiveComponent}</FormColumn>

        {hasPermission(CUSTOMER_PRODUCTS_MANAGE) && (
          <FormColumn size={FormColumnSizeNormal}>
            <div id={domId('createProduct')}>
              <Button onClick={onCreate} text="Create service" />
            </div>
          </FormColumn>
        )}
      </FormRow>

      <div>
        {hasProducts(contracts) ? (
          <React.Fragment>
            <ServicesTable contracts={contracts} />
          </React.Fragment>
        ) : (
          <InformationLineFc>No services found</InformationLineFc>
        )}
      </div>
    </Page>
  );
};

import classnames from 'classnames';
import React, {FC} from 'react';

import * as AppRoutes from '../../appRoutes';
import {getLog} from '../../log';
import {PropsWithClassName} from '../../model';
import {OverviewBoxFc} from '../common/OverviewBox';
import {OverviewBoxGridFc} from '../common/OverviewBoxGrid';
import PageSubtitle from '../common/PageSubtitle';

const log = getLog('SettlementOverview', 'INFO');

export interface SettlementOverviewFcProps extends PropsWithClassName {
  pendingSettlementsCount?: number;
  readyForApprovalSettlementsCount?: number;
}

export const SettlementOverviewFc: FC<SettlementOverviewFcProps> = (props) => {
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  log.debug('props', props);
  const {pendingSettlementsCount, readyForApprovalSettlementsCount} = props;
  const titlePending = `${
    pendingSettlementsCount === undefined ? 'Loading' : pendingSettlementsCount > 0 ? pendingSettlementsCount : 'No'
  } Pending`;
  const titleReadyForApproval = `${
    readyForApprovalSettlementsCount === undefined
      ? 'Loading'
      : readyForApprovalSettlementsCount > 0
      ? readyForApprovalSettlementsCount
      : 'No'
  } Ready For Approval`;
  return (
    <div className={classnames('Overview', props.className)}>
      <PageSubtitle subtitle="Overview" />

      <OverviewBoxGridFc>
        <OverviewBoxFc
          icon="question"
          title={titlePending}
          warning={!!pendingSettlementsCount}
          link={formatWithBrand(AppRoutes.SETTLEMENT_PENDING_LIST)}
        />
        <OverviewBoxFc
          icon="security"
          title={titleReadyForApproval}
          warning={!!readyForApprovalSettlementsCount}
          link={formatWithBrand(AppRoutes.SETTLEMENT_READY_FOR_APPROVAL_LIST)}
        />
        {/*<OverviewBox icon='unarchive' title='Archive'*/}
        {/*<OverviewBox icon="trending_up" title="Summary" link={formatWithBrand(AppRoutes.SETTLEMENT_SUMMARY)} />*/}
        <OverviewBoxFc icon="folder" title="Archive" link={formatWithBrand(AppRoutes.SETTLEMENT_ARCHIVE)} />
        <OverviewBoxFc icon="search" title="Search" link={formatWithBrand(AppRoutes.SETTLEMENT_SEARCH)} />
      </OverviewBoxGridFc>

      {/*<SettlementTotalsGraph link={formatWithBrand(AppRoutes.SETTLEMENT_MONTHLY)} />*/}
    </div>
  );
};

import {Menu} from '@telia/styleguide';
import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {UserAccessStatusType} from '../../model';
import {ALL_CUSTOMERS_READ, CUSTOMER_STATISTICS_REPORT} from '../../permissions';
import teliaLogoReversed from '../../resources/images/telia-logo-reversed.png';
import teliaLogo from '../../resources/images/telia-logo.png';

const log = getLog('Header', 'INFO');

interface MenuItem {
  text: string;
  url: string;
}

const findActiveMenuLink = (menuLinks: MenuItem[], pathname: string) =>
  menuLinks.reduce((res, link, i) => (res >= 0 ? res : pathname.startsWith(link.url) ? i : res), -1);

export const HeaderFc: FC = (_) => {
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const location = useLocation();
  const {user, hasPermission, isTelia, isCustomer, loadingUser, userBrands, currentUserBrand} = useUser();
  const menuLinks: MenuItem[] = [];
  const menuHeadings: MenuItem[] =
    userBrands && userBrands.length > 0
      ? userBrands.map(({id, name}) => ({
          text: name,
          url: `/brand/${id}?=location#${encodeURI(window.location.href)}`,
        }))
      : [
          {
            text: 'Hidden heading for logged out',
            url: `/`,
          },
        ];
  if (!currentUserBrand && userBrands && userBrands?.length > 1) {
    menuLinks.push({text: 'Please select a Brand from the list above.', url: '/'});
  } else {
    if (user) {
      if (isTelia()) {
        if (user.userAccessStatusType === UserAccessStatusType.APPLYING) {
          menuLinks.push({
            text: 'Request Access',
            url: AppRoutes.REQUEST_ACCESS,
          });
        }
        if (hasPermission(ALL_CUSTOMERS_READ)) {
          menuLinks.push({
            text: 'Provisioning',
            url: formatWithBrand(AppRoutes.PROVISIONING),
          });
          menuLinks.push({
            text: 'Settlement',
            url: formatWithBrand(AppRoutes.SETTLEMENT),
          });
          menuLinks.push({text: 'Report', url: formatWithBrand(AppRoutes.REPORT)});
          menuLinks.push({
            text: 'Management',
            url: formatWithBrand(AppRoutes.MANAGEMENT),
          });
        }
      } else if (isCustomer()) {
        menuLinks.push({
          text: 'Provisioning',
          url: formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER__customerId, user.customerId!),
        });
        hasPermission(CUSTOMER_STATISTICS_REPORT) &&
          menuLinks.push({
            text: 'Report',
            url: formatWithBrand(AppRoutes.REPORT),
          });
      }
    }
    menuLinks.push({text: 'Documents', url: AppRoutes.DOCUMENTS});
  }
  log.debug('render', {user, userBrands, currentUserBrand, menuHeadings, menuLinks});
  return (
    <header>
      <Menu
        menuLinks={menuHeadings?.map(({text, url}) => ({
          heading: {
            text,
            url,
          },
          links: menuLinks,
        }))}
        buttonValues={{
          login: 'LOG IN',
          myPage: 'MY PROFILE',
          frontPage: 'Front page',
          closeButton: 'Close',
        }}
        activeLinkIndex={findActiveMenuLink(menuLinks, location.pathname)}
        activeIndex={menuHeadings.reduce((x, {text}, i) => (text === currentUserBrand?.name ? i : x), 0)}
        loginUrl={AppRoutes.LOGIN}
        isLoggedIn={!!user}
        myPageUrl={user && AppRoutes.USER}
        logoImageDesktopPath={teliaLogo}
        logoImageInverseDesktopPath={teliaLogoReversed}
        logoTitle={'Telia Bulk Messaging'}
        logoUrl={AppRoutes.HOME}
        linkTemplate={({url, ...otherProps}) => <Link to={url} {...otherProps} />}
        isLoading={loadingUser}
      />
    </header>
  );
};

import {CountriesDocuments, Country, Document, ID, NO, SE, allCountries} from '@telia/cpa-web-common/dist/model';

import {realHost} from '../utils/host';

interface UseDocuments {
  countriesDocuments: CountriesDocuments;
  getCountryDocuments: (countryId?: ID) => Document[];
}

const documentPrefix = `Telia_Norge_CPA_Agreement`;

const CPA_AGREEMENT: Document = {name: 'CPA Agreement', url: `${documentPrefix}.pdf`};
const APPENDIX_1: Document = {
  name: 'Appendix 1: General Terms and Conditions for CPA Services',
  url: `${documentPrefix}_APPENDIX_1_General_Terms_and_Conditions_for_CPA_Services.pdf`,
};
const APPENDIX_2: Document = {
  name: 'Appendix 2: Contact information',
  url: `${documentPrefix}_APPENDIX_2_Contact_information.pdf`,
};
const APPENDIX_3: Document = {
  name: 'Appendix 3: Product Summary',
  url: `${documentPrefix}_APPENDIX_3_Product_Summary.pdf`,
};
const APPENDIX_4: Document = {name: 'Appendix 4: Fees', url: `${documentPrefix}_APPENDIX_4_Fees.pdf`};
const APPENDIX_5: Document = {
  name: 'Appendix 5: Data Processing Agreement',
  url: `${documentPrefix}_APPENDIX_5_Data_Processing_Agreement.pdf`,
};
const APPENDIX_6: Document = {
  name: 'Appendix 6: Service Specific Terms and Conditions for SenderID Protection',
  url: `${documentPrefix}_APPENDIX_6_Service_Specific_Terms_and_Conditions_for_SenderID_Protection.pdf`,
};

const NO_DOCUMENTS: Document[] = [
  CPA_AGREEMENT,
  APPENDIX_1,
  APPENDIX_2,
  APPENDIX_3,
  APPENDIX_4,
  APPENDIX_5,
  APPENDIX_6,
];

const SE_DOCUMENTS: Document[] = [
  // TODO: enable when new documents are received, 2024-08-02 at the latest
  // CPA_AGREEMENT,
  // APPENDIX_1,
  // APPENDIX_2,
  // APPENDIX_3,
  // APPENDIX_4,
  // APPENDIX_5,
  // APPENDIX_6,
];

const buildDocumentUrl = ({name, url}: Document, countryId: ID, host: string) => ({
  name,
  url: `${host}/documents/commercial/${countryId.toLowerCase()}/cpa_agreement/${url}`,
});

export const getDocuments = (countries: Country[], host: string) => {
  let countriesDocuments: CountriesDocuments = {} as CountriesDocuments;

  countries.forEach(({id}) => {
    const countryId = id as keyof typeof allCountries;
    switch (countryId) {
      case NO.id:
        countriesDocuments[countryId] = NO_DOCUMENTS.map((document) => buildDocumentUrl(document, id, host));
        break;
      case SE.id:
        countriesDocuments[countryId] = SE_DOCUMENTS.map((document) => buildDocumentUrl(document, id, host));
        break;
      default:
        countriesDocuments[countryId] = [];
        break;
    }
  });

  return countriesDocuments;
};

export const useDocuments = (): UseDocuments => {
  const host = realHost();

  const countriesDocuments = getDocuments(Object.values(allCountries), host);

  const isKeyOfCountryList = (key: string): key is keyof typeof allCountries => {
    return key in countriesDocuments;
  };

  const getCountryDocuments = (countryId?: ID) =>
    countryId && isKeyOfCountryList(countryId) ? countriesDocuments[countryId] : [];

  return {countriesDocuments, getCountryDocuments};
};

import React, {FC} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {PropsWithClassName} from '../../model';
import ErrorBoundary from '../ErrorBoundary';
import Page from '../common/Page';
import PageTitle from '../common/PageTitle';
import {SubmenuFc, SubmenuItemFc} from '../common/Submenu';
import {RerouterFc} from '../management/Rerouter';
import {AccessNumberHistory} from './AccessNumberHistory';
import {ReportDcbFc} from './ReportDcb';
import {ReportMessageByIdFc} from './ReportMessageById';
import {ReportMessagesFc} from './ReportMessages';
import {ReportMonthlyUsageFc} from './ReportMonthlyUsage';
import {ReportOverviewFc} from './ReportOverview';
import {ReportReservedOasFc} from './ReportReservedOas';
import {ReportStatistics} from './ReportStatistics';

const log = getLog('ReportMain', 'INFO');

export const ReportMainFc: FC<PropsWithClassName> = (props) => {
  const {user, isTelia, isCustomer} = useUser();
  const {pathname} = useLocation();
  const subPath = AppRoutes.useSubPath(AppRoutes.REPORT);
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {currentUserBrand} = useUser();

  log.debug('render', {user, isTelia, isCustomer});
  return (
    <Page>
      {/*<BreadCrumbs>*/}
      {/*<BreadCrumb text='Reports' link={formatWithBrand(AppRoutes.SETTLEMENT)}/>*/}
      {/*</BreadCrumbs>*/}

      <PageTitle title="Report" />

      <div>
        <SubmenuFc ariaLabel={'Report submenu'}>
          {(user && isTelia() && (
            <React.Fragment>
              <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_OVERVIEW)} text="Overview" />
              <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_MONTHLY)} text="Monthly Usage" />
              <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_STATISTICS)} text="Detailed Statistics" />
              <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_MESSAGES)} text="Detailed Messages" />
              {currentUserBrand?.id === 'TELIA_NO' && (
                <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_DCB)} text="Charging & Billing" />
              )}
              <SubmenuItemFc
                link={formatWithBrand(AppRoutes.REPORT_ACCESS_NUMBER_HISTORY)}
                text="Access Number History"
              />
              <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_RESERVED_OAS)} text="Reserved Sender ID" />
            </React.Fragment>
          )) ||
            (user && isCustomer() && (
              <React.Fragment>
                <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_OVERVIEW)} text="Overview" />
                <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_STATISTICS)} text="Detailed Statistics" />
                <SubmenuItemFc link={formatWithBrand(AppRoutes.REPORT_MESSAGES)} text="Detailed Messages" />
              </React.Fragment>
            ))}
        </SubmenuFc>
        <ErrorBoundary key={pathname}>
          <Routes>
            <Route index element={<Navigate replace to={formatWithBrand(AppRoutes.REPORT_OVERVIEW)} />} />
            <Route path={subPath(AppRoutes.REPORT_OVERVIEW)} element={<ReportOverviewFc />} />
            <Route path={subPath(AppRoutes.REPORT_MONTHLY)} element={<ReportMonthlyUsageFc />} />
            <Route path={subPath(AppRoutes.REPORT_STATISTICS)} element={<ReportStatistics />} />
            <Route path={subPath(AppRoutes.REPORT_MESSAGE__messageId)} element={<ReportMessageByIdFc />} />
            <Route path={subPath(AppRoutes.REPORT_MESSAGES)} element={<ReportMessagesFc />} />
            <Route
              path={subPath(AppRoutes.REPORT_DCB)}
              element={
                <RerouterFc
                  isDisplayed={currentUserBrand?.id === 'TELIA_NO'}
                  navigateTo={formatWithBrand(AppRoutes.REPORT_OVERVIEW)}
                >
                  <ReportDcbFc />
                </RerouterFc>
              }
            />
            <Route path={subPath(AppRoutes.REPORT_ACCESS_NUMBER_HISTORY)} element={<AccessNumberHistory />} />
            <Route path={subPath(AppRoutes.REPORT_RESERVED_OAS)} element={<ReportReservedOasFc />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </Page>
  );
};

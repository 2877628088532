import {gql, useQuery} from '@apollo/client';
import {isV4CidrAddress, isV4IpAddress, isValidAddress} from '@telia/cpa-web-common/src/ipAddresses';
import {Button, Tooltip} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import saveCompanyTechnicalMutation from '../../graphql/mutation/saveCompanyTechnical.graphql';
import companyTechnicalQuery from '../../graphql/query/companyTechnical.graphql';

import './AccessControlList.scss';

import {Entity, asEntity, useFormState} from '../../hooks/useFormState';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {CompanyTechnical, ID} from '../../model';
import {CUSTOMER_TECHNICAL_MANAGE, TELIA_USERS_MANAGE} from '../../permissions';
import Loading from '../Loading';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {Icon} from '../common/Icon';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';

interface CompanyTechnicalQuery {
  companyTechnical: CompanyTechnical;
}

type AccessControlList = {
  addresses: AccessControlListAddress[];
};

type AccessControlListAddress = {
  address: string;
  description?: string;
};

const log = getLog('CompanyTechnical', 'INFO');

export const AccessControlList: FC = () => {
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};
  const {hasPermission} = useUser();
  const {loading, data: {companyTechnical} = {}} = useQuery<CompanyTechnicalQuery>(gql(companyTechnicalQuery), {
    variables: {customerId},
    skip: !customerId,
  });

  const formState = useFormState({
    initialEntity: {
      addresses: companyTechnical ? ([...companyTechnical.ips, ...companyTechnical.cidrs] as unknown as Entity) : [],
    },
    isEditing: false,
  });

  const {isEditing, onEdit, onCancel, onChange, entityAs, putError} = formState;
  const accessControlList = entityAs<AccessControlList>();

  const saveCompanyTechnical = useMutationWrap<
    {saveCompanyTechnical: CompanyTechnical},
    {customerId: ID; companyTechnical: CompanyTechnical}
  >(gql(saveCompanyTechnicalMutation));

  const onDelete = (indexToDelete: number) =>
    onChange('addresses')([...accessControlList.addresses.filter((_, index) => indexToDelete !== index)]);

  const isAddressesValid = (accessControlList: AccessControlList): boolean => {
    let isValid = true;

    if (!accessControlList.addresses) {
      accessControlList.addresses = [];
    }

    accessControlList.addresses.forEach(({address}, i) => {
      if (!isValidAddress(address)) {
        putError(`addresses[${i}]`, 'Invalid IPv4/CIDR address');
        isValid = false;
      }
    });

    return isValid;
  };

  const onSave = async () => {
    log.debug('onSave', {customerId, accessControlList});

    if (isAddressesValid(accessControlList)) {
      const companyTechnical: CompanyTechnical = {
        ips: Array.from(new Set(accessControlList.addresses.filter(({address}) => isV4IpAddress(address)))),
        cidrs: Array.from(new Set(accessControlList.addresses.filter(({address}) => isV4CidrAddress(address)))),
      };

      const {data} = await saveCompanyTechnical({
        loadingText: 'Saving Company Technical...',
        successText: 'Company Technical saved',
        variables: {customerId, companyTechnical},
      });

      const companyTechnicalResponse = data?.saveCompanyTechnical;
      log.info('saveCompanyTechnicalMutate resolved', companyTechnicalResponse);
      companyTechnicalResponse &&
        formState.onSaved(asEntity({addresses: [...companyTechnicalResponse.ips, ...companyTechnicalResponse?.cidrs]}));
    } else {
      toast.error('Entered invalid IPv4/CIDR address');
    }
  };

  log.debug('render', {customerId, isEditing, accessControlList, onEdit, onCancel});
  return (
    <>
      {hasPermission(CUSTOMER_TECHNICAL_MANAGE) && (
        <>
          {loading || !accessControlList ? (
            <Loading />
          ) : (
            <>
              <PageSubtitle>
                Company Technical Configuration
                {hasPermission(TELIA_USERS_MANAGE) && (
                  <div className={classnames('inlineBlock marginLeft')}>
                    {!isEditing ? (
                      <div>
                        <Button text={'Edit'} className="" onClick={formState.onEdit} />
                      </div>
                    ) : (
                      <div>
                        <Button text={'Save'} onClick={onSave} kind={Button.kinds.primary} />
                        <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
                      </div>
                    )}
                  </div>
                )}
              </PageSubtitle>
              <section className={'form-group'}>
                <SectionTitle>
                  Access Control List
                  <Tooltip text="List of IP ranges from which requests will be sent towards SMPP endpoints" />
                </SectionTitle>
                {accessControlList?.addresses && !accessControlList.addresses.isEmpty()
                  ? accessControlList.addresses?.map((_address, i) => (
                      <FormRow key={`address-row-${i}`}>
                        <FormColumn>
                          <FieldWithFormState
                            {...(i === 0 && {label: 'IP', tip: 'Entries should be in format of IPv4 or CIDR notation'})}
                            formState={formState}
                            entityFieldId={`addresses[${i}].address`}
                            placeholder="0.0.0.0"
                            className={'company-technical__address'}
                          />
                          <FieldWithFormState
                            {...(i === 0 && {
                              label: 'Description',
                            })}
                            formState={formState}
                            defaultValue=""
                            entityFieldId={`addresses[${i}].description`}
                            type={FieldTypes.textarea}
                            className={'company-technical__description'}
                          />
                          {isEditing && (
                            <div className="company-technical__icon--wrapper">
                              <Icon
                                icon="trash"
                                onClick={() => {
                                  onDelete(i);
                                }}
                                className={'company-technical__icon company-technical__icon--delete'}
                              />
                            </div>
                          )}
                        </FormColumn>
                      </FormRow>
                    ))
                  : !isEditing && (
                      <>
                        <FormRow>
                          <FormColumn>
                            <Field label="IP" defaultValue="-" />
                          </FormColumn>
                        </FormRow>
                      </>
                    )}
                {isEditing && (
                  <FormRow>
                    <FormColumn>
                      <div>
                        <Button
                          kind="normal"
                          text="Add row"
                          onClick={() => {
                            onChange('addresses')([...accessControlList.addresses, {address: ''}]);
                          }}
                        />
                      </div>
                    </FormColumn>
                  </FormRow>
                )}
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

#import '../fragment/productTechnicalExpanded.graphql'

mutation saveProductTechnicalExpanded(
  $customerId: String!
  $contractId: String!
  $productId: String!
  $productTechnical: ProductTechnicalExpandedInput!
) {
  saveProductTechnicalExpanded(
    customerId: $customerId
    contractId: $contractId
    productId: $productId
    productTechnical: $productTechnical
  ) {
    ...productTechnicalExpandedFragment
  }
}

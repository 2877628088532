import React, {FC, useEffect} from 'react';

import {EntityFieldValue, FormState} from '../../../hooks/useFormState';
import {getLog} from '../../../log';
import {ID} from '../../../model';
import Error from '../Error';
import Field, {FieldProps, FieldTypes} from './Field';
import {SelectOption} from './_Select';

const log = getLog('FieldWithFormState', 'INFO');

export type FieldWithFormStateProps = Partial<FieldProps> & {
  formState: FormState;
  entityFieldId: ID;
  onChangeAlso?: (newValue: EntityFieldValue) => any;
  error?: string;
};

export const FieldWithFormState: FC<FieldWithFormStateProps> = (props) => {
  const {formState, entityFieldId, onChangeAlso, name, isEditing, error, value: propsValue, tip, ...otherProps} = props;
  const value = propsValue || formState.subEntityAt(entityFieldId as string);

  useEffect(() => {
    if (otherProps.type === FieldTypes.select) {
      /* If dropdown component gets a value which is not included in its options, for example
       * because it's from preferences and doesn't exist in this brand, unset the value.
       */
      if (
        value &&
        otherProps.options !== undefined &&
        !otherProps.options?.some((option) => (typeof option === 'string' ? option === value : option.id === value))
      ) {
        log.warn(
          `${entityFieldId} '${value}' does not exist in ${otherProps.label} dropdown options, unsetting preference and url param...`
        );
        formState.onChange(entityFieldId)(null);
      }
    }
  }, [otherProps.type === FieldTypes.select && otherProps.options]);

  try {
    const fieldProps = {
      ...otherProps,
      isEditing: isEditing !== undefined ? isEditing : formState.isEditing,
      value,
      onChange: (newValue: EntityFieldValue) => {
        log.debug('onChange', {entityFieldId, newValue});
        formState.onChange(entityFieldId)(newValue);
        onChangeAlso && onChangeAlso(newValue);
      },
      error: error || formState.findError(entityFieldId),
      name: name || entityFieldId,
      tip: entityFieldId === 'endDate' ? 'Date is inclusive.' + (tip ? ' ' + tip : '') : tip,
    } as unknown as FieldProps;
    log.debug('render', entityFieldId, value, {props});
    return <Field {...fieldProps} />;
  } catch (error) {
    log.error(error);
    return <Error text={`Error rendering FieldWithFormState for ${entityFieldId}`} error={error} />;
  }
};

import {gql, useQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FunctionComponent} from 'react';
import {useParams} from 'react-router-dom';

import generateSecretMutation from '../../graphql/mutation/generateSecret.graphql';
import saveProductTechnicalMutation from '../../graphql/mutation/saveProductTechnical.graphql';
import productTechnicalQuery from '../../graphql/query/productTechnical.graphql';

import {Entity, FormStateOptions, asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {
  ApiCredentials,
  ApolloObject,
  DeliveryEndpoint,
  EndpointProtocol,
  ID,
  ProductTechnical,
  WithId,
} from '../../model';
import {CUSTOMER_TECHNICAL_MANAGE, PRODUCT_FEATURES_MANAGE} from '../../permissions';
import Loading from '../Loading';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';
import {ServiceTechnicalFeatures} from './ServiceTechnicalFeatures';

const log = getLog('ProductTechnical', 'INFO');

const protocols = ['REST', 'SOAP'];

interface ServiceTechnicalQuery {
  productTechnical: WithId<
    ApolloObject<{
      endpoints: ApolloObject<{
        mo: ApolloObject<DeliveryEndpoint>;
        dr: ApolloObject<DeliveryEndpoint>;
      }>;
      apiCredentials: ApolloObject<ApiCredentials>;
    }>
  >;
}

interface ServiceTechnicalProps {
  productTypeId: ID;
}

export const ServiceTechnical: FunctionComponent<ServiceTechnicalProps> = ({productTypeId}) => {
  const {contractId, customerId, productId} = useParams<{contractId: ID; customerId: ID; productId: ID}>() as {
    contractId: ID;
    customerId: ID;
    productId: ID;
  };

  const {hasProductTechnicalApiCredentials, hasProductTechnicalDr, hasProductTechnicalMo} = useProductTypes();
  const {showModal} = useModal();
  const {
    loading,
    error: productTechnicalQueryError,
    data: {productTechnical} = {},
  } = useQuery<ServiceTechnicalQuery>(gql(productTechnicalQuery), {
    variables: {contractId, customerId, productId},
  });

  const initialEntity = productTechnical
    ? asEntity(productTechnical)
    : {
        __typename: 'ProductTechnical',
        endpoints: {
          __typename: 'DeliveryEndpoints',
          mo: {
            __typename: 'DeliveryEndpoint',
          },
          dr: {
            __typename: 'DeliveryEndpoint',
          },
        },
        apiCredentials: {
          __typename: 'Credentials',
        },
      };

  //  TODO: confirm not loading from ApolloCache
  // const formFragmentOptions: UseApolloCacheEntityProps = {
  //   fragment: productTechnicalFragment,
  //   fragmentName: 'productTechnical',
  //   entityId: productId,
  //   newEntity: initialEntity,
  // };
  // const formStateOptions = useApolloCacheEntity(formFragmentOptions);
  const formStateOptions: FormStateOptions = {initialEntity, isEditing: false};
  const formState = useFormState(formStateOptions);

  const saveProductTechnical = useMutationWrap<
    {saveProductTechnical: ProductTechnical},
    {customerId: ID; contractId: ID; productId: ID; productTechnical: ProductTechnical}
  >(gql(saveProductTechnicalMutation));
  const onSave = () => {
    const technical = formState.entityAs<ProductTechnical & {id?: string}>();
    delete technical.apiCredentials; //  do not send apiCredentials since shouldn't be modified at all
    delete technical.id; //  do not send id since is only for client apollo cache
    if (technical.protocol !== EndpointProtocol.SMPP) {
      delete technical.smppApiConfig;
    }
    technical.protocol = (
      technical.endpoints?.mo.protocol ? technical.endpoints?.mo.protocol : technical.endpoints?.dr.protocol
    ) as EndpointProtocol;
    log.debug('onSave', {customerId, contractId, productId, technical});
    saveProductTechnical({
      loadingText: 'Saving service technical...',
      successText: 'ServiceTechnical saved',
      variables: {customerId, contractId, productId, productTechnical: technical},
    }).then(({data}) => {
      const {saveProductTechnical} = data || {saveProductTechnical: undefined};
      log.info('saveServiceTechnicalMutate resolved', saveProductTechnical);
      saveProductTechnical && formState.onSaved(asEntity(saveProductTechnical));
    });
  };

  //  API Credentials
  const onGenerateSecret = () => {
    const config = {
      title: 'Client Secret generation',
      content: (
        <span>
          This action will <b>invalidate the current credentials</b>, a new secret will be generated and saved as the
          valid credentials
        </span>
      ),
      onConfirm: onGenerateSecretConfirmed,
      confirmText: 'Generate new secret',
    };
    showModal(config);
  };

  const generateSecret = useMutationWrap<
    {generateSecret: ApiCredentials & {secret: string}},
    {customerId: ID; contractId: ID; productId: ID}
  >(gql(generateSecretMutation));
  const onGenerateSecretConfirmed = () => {
    log.debug('generateSecret confirmed');
    generateSecret({
      loadingText: 'Generating secret...',
      successText: 'Secret updated',
      variables: {customerId, contractId, productId},
    }).then(({data}) => {
      const {generateSecret} = data || {generateSecret: undefined};
      log.info('generateSecret resolved', generateSecret);
      if (generateSecret) {
        formState.onChange('apiCredentials.secretLastUpdated')(generateSecret.secretLastUpdated!);
        formState.onChange('apiCredentials.clientId')(generateSecret.clientId);
        onSecretGenerated(generateSecret.secret);
      }
    });
  };

  const onSecretGenerated = (secret: string) => {
    const config = {
      title: 'Client Secret updated',
      content: (
        <span>
          The client secret has been updated. This is the only time it will be displayed. The <b>new secret</b> is:
          <div className="purpleGreyBox">{secret}</div>
        </span>
      ),
    };
    showModal(config);
  };

  //  Endpoints passwords
  const onShowEndpointPassword = (password: string) => () => {
    const config = {
      title: `Endpoint Password`,
      content: (
        <span>
          The current <b>password</b> is: <div className="purpleGreyBox">{password}</div>
        </span>
      ),
    };
    showModal(config);
  };

  log.debug('render', {productTypeId, productTechnical});
  const {isEditing, entity, onCancel, onChange, findError} = formState;
  const {hasPermission} = useUser();
  const formButtons = (inPageSubtitle: boolean) => (
    <div className={classnames({'inlineBlock marginLeft': inPageSubtitle})}>
      {!isEditing ? (
        <div>
          {hasPermission(CUSTOMER_TECHNICAL_MANAGE) && <Button text={'Edit'} className="" onClick={formState.onEdit} />}
        </div>
      ) : (
        <div>
          <Button text={'Save'} className="" onClick={onSave} kind={Button.kinds.primary} />
          <Button text={'Cancel'} className="" onClick={onCancel} kind={Button.kinds.cancel} />
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <hr className={'horizontal-rule marginTop2'} />
      <PageSubtitle>
        Service Technical Configuration
        {formButtons(true)}
      </PageSubtitle>

      {!entity ? (
        <Loading />
      ) : (
        <React.Fragment>
          {hasProductTechnicalMo(productTypeId) && (
            <section className={'form-group'}>
              <SectionTitle title={'Mobile Originated Callback'} />

              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'endpoints.mo.endpoint'}
                    label="Endpoint"
                    placeholder={'https://...'}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'endpoints.mo.protocol'}
                    label="Protocol"
                    type={FieldTypes.select}
                    options={protocols}
                  />
                  <div></div>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'endpoints.mo.username'}
                    label="Username"
                    autocomplete={`service ${entity.id} mo username`}
                  />
                  {isEditing ? (
                    <FieldWithFormState
                      formState={formState}
                      entityFieldId={'endpoints.mo.password'}
                      label="Password"
                      type={FieldTypes.password}
                      autocomplete={`service ${entity.id} mo password`}
                    />
                  ) : (
                    <Field
                      label="Password"
                      type={FieldTypes.element}
                      value={
                        <Button
                          text={'show'}
                          isDisabled={isEditing}
                          onClick={onShowEndpointPassword(
                            ((entity.endpoints as Entity).mo as Entity).password as string
                          )}
                        />
                      }
                      isEditing={false}
                    />
                  )}
                </FormColumn>
              </FormRow>
            </section>
          )}
          {hasProductTechnicalDr(productTypeId) && (
            <section className={'form-group'}>
              <SectionTitle title={'Delivery Report Callback'} />
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'endpoints.dr.endpoint'}
                    label="Endpoint"
                    placeholder={'https://...'}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'endpoints.dr.protocol'}
                    label="Protocol"
                    type={FieldTypes.select}
                    options={protocols}
                  />
                  <div></div>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'endpoints.dr.username'}
                    label="Username"
                    autocomplete={`service ${entity.id} dr username`}
                  />
                  {isEditing ? (
                    <FieldWithFormState
                      formState={formState}
                      entityFieldId={'endpoints.dr.password'}
                      label="Password"
                      type={FieldTypes.password}
                      autocomplete={`service ${entity.id} dr password`}
                    />
                  ) : (
                    <Field label="Password" type={FieldTypes.element} isEditing={false}>
                      <Button
                        text={'show'}
                        isDisabled={isEditing}
                        onClick={onShowEndpointPassword(
                          ((entity.endpoints as Entity)?.dr as Entity)?.password as string
                        )}
                      />
                    </Field>
                  )}
                </FormColumn>
              </FormRow>
            </section>
          )}
          {hasProductTechnicalApiCredentials(productTypeId) && (
            <section className={'form-group'}>
              <SectionTitle title={'Telia API Credentials'} />

              <FormRow>
                <FormColumn>
                  <Field
                    label="Client ID"
                    value={(entity.apiCredentials as Entity)?.clientId as string}
                    defaultValue={'Not created yet'}
                    isEditing={false}
                    onChange={onChange('apiCredentials.clientId')}
                    tip={`It's created during the first Client Secret generation`}
                    error={findError('ApiCredentials.id')} // clientId vs id?
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <Field
                    label="Client Secret"
                    type={FieldTypes.element}
                    value={
                      <Button
                        text={(entity.apiCredentials as Entity)?.secretLastUpdated ? 'Re-generate' : 'Generate'}
                        isDisabled={isEditing}
                        onClick={onGenerateSecret}
                      />
                    }
                    isEditing={false}
                    tip={
                      <span>
                        It <b>displays only once</b> after generation
                      </span>
                    }
                  />
                  <div></div>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <Field
                    label="Client Secret Last Updated"
                    value={
                      (entity.apiCredentials as Entity)?.secretLastUpdated as string
                      //  TODO: timeAgo.ago(entity.apiCredentials.secretLastUpdated)
                    }
                    defaultValue={'NEVER'}
                    isEditing={false}
                  />
                </FormColumn>
              </FormRow>
            </section>
          )}

          {hasPermission(PRODUCT_FEATURES_MANAGE) && <ServiceTechnicalFeatures formState={formState} />}

          <FormRow>
            <FormColumn>{formButtons(false)}</FormColumn>
          </FormRow>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import {gql} from '@apollo/client';
import {constants, period} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import util from 'util';

import contractFragment from '../../graphql/fragment/contract.graphql';
import saveContractMutation from '../../graphql/mutation/saveContract.graphql';
import customerQuery from '../../graphql/query/customer.graphql';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useShowInactive} from '../../hooks/useShowInactive';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Contract, Customer, Dict, ID} from '../../model';
import {CUSTOMER_CONTRACTS_MANAGE, CUSTOMER_PRODUCTS_MANAGE} from '../../permissions';
import Form from '../common/Form';
import FormColumn, {FormColumnSizeNormal} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';
import {ServicesTable} from './ServicesTable';

const {contractTypes, EXTERNAL_CONTRACT_TYPE, INTERNAL_CONTRACT_TYPE} = constants;

const log = getLog('Contract', 'INFO');

interface ContractFcProps {
  contracts?: Array<Contract>;
  refetchCustomer: () => void;
}

interface CustomerQuery {
  customer: Customer;
}

const customerQueryGql = gql(customerQuery);

export const ContractFc: FC<ContractFcProps> = (props) => {
  const {contracts, refetchCustomer} = props;
  const {customerId, contractId} = useParams() as Dict<ID>;
  const contract = contracts?.find(({id}) => id === contractId);
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {hasPermission, currentUserBrand} = useUser();
  const {showInactiveComponent} = useShowInactive();
  const {showModal, hideModal} = useModal();
  const {getName, canReserveOa} = useProductTypes();
  const formFragmentOptions: UseApolloCacheEntityProps = {
    fragment: contractFragment,
    entityId: contractId,
    newEntity: {
      name: null,
      startDate: null,
      endDate: null,
      type: 'EXTERNAL',
      invoiceReference: null,
      invoiceAccountId: null,
      invoiceIncludeVat: true,
      comment: null,
    },
  };
  const formStateOptions = useApolloCacheEntity(formFragmentOptions);
  const formState = useFormState(formStateOptions);
  const {entityAs, initialEntity, entity, isEditing, onEdit, onCancel, onChange} = formState;

  const saveContract = useMutationWrap<{saveContract: Contract}, {customerId: ID; contract: Contract}>(
    gql(saveContractMutation)
  );

  const onSave = () => {
    const {name: contractName, endDate: finalEndDate} = entityAs<Contract>();
    const initialEndDate = initialEntity.endDate;
    const inheritsEndDateProducts =
      contract?.products?.filter(
        ({inheritsEndDate, endDate: productEndDate}) => inheritsEndDate || (!initialEndDate && !productEndDate)
      ) || [];
    if (initialEndDate === finalEndDate || inheritsEndDateProducts.isEmpty()) {
      onConfirmSave();
    } else {
      showModal({
        title: 'Update Contract End Date',
        content: (
          <>
            <p>
              Are you sure you want to update the <b>{contractName && `'${contractName}'`}</b> contract's end date?
            </p>
            <span>This will also adjust the end date of the following services:</span>
            <List>
              {inheritsEndDateProducts.map(({id: productId, productType}) => (
                <ListItem key={productId}>
                  <div>
                    <span>{getName(productType)} </span>
                    <span> (id: {productId})</span>
                  </div>
                </ListItem>
              ))}
            </List>
            <p> These services will NOT be able to send messages after the end date has passed.</p>
          </>
        ),
        typeToConfirmText: 'update',
        confirmText: 'Update',
        confirmType: 'primary',
        onConfirm: () => onConfirmSave(),
      });
    }
  };

  const onConfirmSave = () => {
    log.debug('onSave', {customerId, entity});
    saveContract({
      loadingText: 'Saving contract...',
      successText: 'Contract saved',
      variables: {customerId, contract: entityAs<Contract>()},
      update: (proxy, {data: updateData}) => {
        const {saveContract} = updateData || {};
        if (!saveContract) return;
        if (initialEntity.id) {
          //  Contract already existed
          log.debug('saveContractMutate update. Existing contract updated', saveContract);
          if (initialEntity.endDate !== saveContract.endDate) {
            log.warn(
              'Contract end date has been modified. Services end date might have been adjusted. Re-fetching customer...'
            );
            refetchCustomer();
            contract?.products.some(({productType}) => canReserveOa(productType)) &&
              proxy.evict({id: 'ROOT_QUERY', fieldName: 'reservedOas', args: {countryId: currentUserBrand?.countryId}});
          }
        } else {
          log.debug('saveContractMutate update. New contract created', saveContract);
          const data = proxy.readQuery<CustomerQuery, Dict<ID>>({
            query: customerQueryGql,
            variables: {customerId},
          });
          if (!data) {
            throw new Error(`Couldn't get Customer from Apollo Cache`);
          }
          log.debug('saveContractMutate update. Customer query data', util.inspect(data));

          const contracts = [...(data.customer.contracts || []), {...saveContract, products: []}];
          log.debug('saveContractMutate update. Contracts', contracts);
          proxy.writeQuery({query: customerQueryGql, data: {customer: {...data.customer, contracts}}});
          navigate(
            formatWithBrand(
              AppRoutes.PROVISIONING_CUSTOMER_CONTRACT__customerId_contractId,
              customerId,
              saveContract.id
            ),
            {replace: true}
          );
        }
      },
    }).then(({data}) => {
      const {saveContract} = data || {saveContract: undefined};
      log.debug('saveContractMutate resolved', saveContract);
      saveContract && formState.onSaved(asEntity(saveContract));
      return saveContract;
    });
  };

  log.debug('render', props);
  const formButtons = (inPageSubtitle?: boolean) => (
    <div className={classnames({'inlineBlock marginLeft': inPageSubtitle})}>
      {!isEditing ? (
        <div>{hasPermission(CUSTOMER_CONTRACTS_MANAGE) && <Button text={'Edit'} onClick={onEdit} />}</div>
      ) : (
        <div>
          <Button text={'Save'} onClick={onSave} kind={Button.kinds.primary} />
          <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <PageSubtitle>
        Contract
        {formButtons(true)}
      </PageSubtitle>

      {!entity ? (
        <span>No form entity info</span>
      ) : (
        <Form
          onSubmit={(e) => {
            log.debug('onSubmit');
            entity.messageId &&
              navigate(formatWithBrand(AppRoutes.REPORT_MESSAGE__messageId, entity.messageId as string), {
                replace: true,
              });
          }}
        >
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'id'} label="Contract ID" isEditing={false} />
              <FieldWithFormState formState={formState} entityFieldId={'name'} label="Name" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'startDate'}
                label="Start date"
                type={FieldTypes.date}
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'endDate'}
                label="End date"
                type={FieldTypes.date}
                defaultValue={'no end'}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <Field
                label="Active Status"
                value={period.activeStatus(entity)}
                valueClassName={classnames(period.activeStatus(entity).toLowerCase() + 'Period')}
              />
            </FormColumn>
          </FormRow>

          <section className={'entity-group'}>
            <SectionTitle title={'Invoicing'} />

            <FormRow>
              <FormColumn>
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'type'}
                  label="Contract type"
                  options={contractTypes}
                  type={FieldTypes.select}
                  tip={
                    <ul>
                      <li>
                        <span className="bold">{EXTERNAL_CONTRACT_TYPE}</span> contract which will be invoiced
                      </li>
                      <li>
                        <span className="bold">{INTERNAL_CONTRACT_TYPE}</span> contract do NOT get invoiced
                      </li>
                    </ul>
                  }
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'invoiceReference'}
                  label="Customer invoice ref."
                  onChange={onChange('invoiceReference')}
                />
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'invoiceAccountId'}
                  label="Invoice account ID"
                  onChange={onChange('invoiceAccountId')}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'invoiceCustomerEmail'}
                  label="Invoice e-mail address"
                  onChange={onChange('invoiceCustomerEmail')}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'invoiceIncludeVat'}
                  label="Include VAT"
                  onChange={onChange('invoiceIncludeVat')}
                  type={FieldTypes.checkbox}
                />
              </FormColumn>
            </FormRow>
          </section>

          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'comment'}
                label="Comment"
                onChange={onChange('comment')}
                type={FieldTypes.textarea}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>{formButtons()}</FormColumn>
          </FormRow>
        </Form>
      )}

      {contract && (
        <React.Fragment>
          <hr />
          <PageSubtitle subtitle={`Contract's services`} />

          <FormRow>
            <FormColumn size={FormColumnSizeNormal}>{showInactiveComponent}</FormColumn>

            {hasPermission(CUSTOMER_PRODUCTS_MANAGE) && (
              <FormColumn size={FormColumnSizeNormal}>
                <div>
                  <Link
                    to={formatWithBrand(
                      AppRoutes.PROVISIONING_CUSTOMER_SERVICE_CREATE__customerId_contractId,
                      customerId,
                      contractId
                    )}
                  >
                    <Button text="Create service" />
                  </Link>
                </div>
              </FormColumn>
            )}
          </FormRow>

          <div>
            {contract.products && !contract.products.isEmpty() ? (
              <React.Fragment>
                <ServicesTable contracts={[contract]} />
              </React.Fragment>
            ) : (
              <InformationLineFc>No services found</InformationLineFc>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

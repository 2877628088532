import {NO, allCountries} from '@telia/cpa-web-common/dist/model';
import {List} from '@telia/styleguide';
import React, {FC} from 'react';

import {useDocuments} from '../../hooks/useDocuments';
import {useFormState} from '../../hooks/useFormState';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import InformationLineFc from '../common/InformationLine';
import {ListFc} from '../common/List';
import {ListItemFc} from '../common/ListItem';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {FieldTypes, FieldWithFormState} from '../common/field';

const Link = List.Link;
const log = getLog('DocumentsCommercial');

export const DocumentsCommercial: FC = () => {
  log.debug('render');

  const {currentUserBrand} = useUser();
  const {getCountryDocuments} = useDocuments();

  const formState = useFormState({
    initialEntity: {selectedCountry: currentUserBrand?.countryId || NO.id},
    isEditing: true,
  });

  const selectedCountry = allCountries[formState.entity.selectedCountry?.toString() as keyof typeof allCountries];
  const countryDocuments = getCountryDocuments(selectedCountry?.id);

  return (
    <Page>
      <PageSubtitle subtitle="Commercial" />
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={'selectedCountry'}
            label="Country"
            type={FieldTypes.select}
            options={Object.values(allCountries)}
          />
          <div />
        </FormColumn>
      </FormRow>
      <h4>Telia {selectedCountry?.name} - CPA Agreement</h4>
      <ListFc>
        <>
          {countryDocuments.isEmpty() ? (
            <InformationLineFc
              description={`No documents uploaded ${selectedCountry ? `for ${selectedCountry.name}` : ''}`}
            />
          ) : (
            countryDocuments.map(({name, url}) => (
              <ListItemFc>
                <Link href={`${url}`} target="_blank">
                  {name}
                </Link>
              </ListItemFc>
            ))
          )}
        </>
      </ListFc>
    </Page>
  );
};

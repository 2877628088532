import classnames from 'classnames';
import React, {FC, useMemo} from 'react';

import * as AppRoutes from '../../appRoutes';
import {useServiceCategories} from '../../hooks/useServiceCategories';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {User} from '../../model';
import {SMPP_CONNECTIONS_READ, TELIA_USERS_MANAGE} from '../../permissions';
import {OverviewBoxFc} from '../common/OverviewBox';
import {OverviewBoxGridFc} from '../common/OverviewBoxGrid';
import PageSubtitle from '../common/PageSubtitle';

const log = getLog('ManagementOverview', 'INFO');

export interface OverviewFcProps {
  users: User[];
  pendingUsers: User[];
}

export const OverviewFc: FC<OverviewFcProps> = ({users, pendingUsers}) => {
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {isServiceCategoriesVisible} = useServiceCategories();
  const {user, hasBrandPermission, hasPermission} = useUser();

  const isTeliaAdmin = useMemo(() => hasBrandPermission(TELIA_USERS_MANAGE, '*'), [user]);
  const totalUsers = users ? users.length : 0;
  const totalPendingUsers = pendingUsers ? pendingUsers.length : 0;
  return (
    <div className={classnames('Overview')}>
      <PageSubtitle subtitle="Overview " />

      <OverviewBoxGridFc>
        <OverviewBoxFc icon="chat" title={`Communication`} link={formatWithBrand(AppRoutes.MANAGEMENT_NOTIFICATIONS)} />
        {isTeliaAdmin && (
          <OverviewBoxFc
            icon="replace"
            title={`Message Channels`}
            link={formatWithBrand(AppRoutes.MANAGEMENT_MESSAGE_CHANNELS)}
          />
        )}
        <OverviewBoxFc
          icon="police"
          title={`Permissions & Roles`}
          link={formatWithBrand(AppRoutes.MANAGEMENT_PERMISSIONS)}
        />
        {isServiceCategoriesVisible && (
          <OverviewBoxFc
            icon="tags"
            title={`Service Categories`}
            link={formatWithBrand(AppRoutes.MANAGEMENT_SERVICE_CATEGORIES)}
          />
        )}
        {isTeliaAdmin && (
          <OverviewBoxFc icon="server" title={`SMSCs`} link={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS)} />
        )}
        <OverviewBoxFc
          icon="group"
          title={`${totalUsers} User${totalUsers === 1 ? '' : 's'}`}
          warning={totalUsers === 0}
          link={formatWithBrand(AppRoutes.MANAGEMENT_USERS)}
        />
        <OverviewBoxFc
          icon="user-new"
          title={`${totalPendingUsers} Pending User${totalPendingUsers === 1 ? '' : 's'}`}
          warning={!!totalPendingUsers}
          link={formatWithBrand(AppRoutes.MANAGEMENT_PENDING_USERS)}
        />
        {hasPermission(SMPP_CONNECTIONS_READ) && (
          <OverviewBoxFc
            icon="signal"
            title="Smpp Connections"
            link={formatWithBrand(AppRoutes.MANAGEMENT_SMPP_CONNECTIONS)}
          />
        )}
      </OverviewBoxGridFc>
    </div>
  );
};

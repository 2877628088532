import {CustomerOverview} from '@telia/cpa-web-common/dist/model';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

import './CustomerList.scss';

import * as AppRoutes from '../../appRoutes';
import {domId} from '../../dom';
import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {useProductOverviewTypes} from '../../hooks/useProductOverviewTypes';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useShowInactive} from '../../hooks/useShowInactive';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ALL_CUSTOMERS_MANAGE, ALL_CUSTOMERS_READ} from '../../permissions';
import Loading from '../Loading';
import BreadCrumb from '../common/BreadCrumb';
import BreadCrumbs from '../common/BreadCrumbs';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import HighlightSpan from '../common/HighlightSpan';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import NoPermission from '../common/NoPermission';
import Page from '../common/Page';
import PageTitle from '../common/PageTitle';
import Field from '../common/field/Field';

const log = getLog('CustomerList', 'INFO');

export const CustomerList: FC = (props) => {
  const [filter, setFilter] = useState('');
  const {showInactive, showInactiveComponent} = useShowInactive();
  const {loading, customerOverviews} = useCustomerOverviews();
  const customers = useMemo(
    () => filterCustomerOverviews(customerOverviews, filter, showInactive),
    [customerOverviews, filter, showInactive]
  );
  const {hasPermission} = useUser();

  const customersFilterInput = useRef<HTMLInputElement>();
  useEffect(() => {
    // setFilter('');
    customersFilterInput?.current?.focus();
  }, [customersFilterInput.current]);

  const {formatWithBrand} = AppRoutes.useBrandFormat();

  log.debug('render', {customers});

  if (!hasPermission(ALL_CUSTOMERS_READ)) {
    return <NoPermission />;
  }
  return (
    <Page>
      <BreadCrumbs>
        <BreadCrumb text="Provisioning" link={formatWithBrand(AppRoutes.PROVISIONING)} />
      </BreadCrumbs>

      <PageTitle title="Customers" />
      <FormRow>
        <FormColumn>
          <Field
            placeholder={'Filter'}
            icon={'search'}
            isEditing={true}
            value={filter}
            onChange={(value) => setFilter(((value as string) || '').toLowerCase())}
            inputRef={customersFilterInput}
          />
          {/*<input*/}
          {/*  placeholder={'Filter'}*/}
          {/*  // icon={'search'}*/}
          {/*  // isEditing={true}*/}
          {/*  value={filter}*/}
          {/*  onChange={setFilter}*/}
          {/*  ref={customersFilterInput}*/}
          {/*/>*/}
        </FormColumn>
        {hasPermission(ALL_CUSTOMERS_MANAGE) && (
          <FormColumn>
            <div id="createCustomerButton">
              <Link to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_COMPANY__customerId, AppRoutes._CREATE)}>
                <Button text={'Create customer'} />
              </Link>
            </div>
          </FormColumn>
        )}
      </FormRow>
      <FormRow>
        <FormColumn>{showInactiveComponent}</FormColumn>
      </FormRow>

      {loading ? (
        <Loading />
      ) : (
        <div>
          {customers && !customers.isEmpty() ? (
            <div id="customer-list__wrapper" className={'customer-list--wrapper'}>
              <List id="customer-list" className={'customer-list'}>
                {customers.map((customer) => (
                  <CustomerListItem customer={customer} filter={filter} key={customer.id} />
                ))}
              </List>
            </div>
          ) : (
            <InformationLineFc>
              No customers found with current filter <span className="teliaPurple">{filter}</span>
            </InformationLineFc>
          )}
        </div>
      )}
    </Page>
  );
};

interface IsActive {
  state: string;
}

const isActive: (isActive: IsActive) => boolean = ({state}) => state === 'ACTIVE';

export const filterCustomerOverviews: (
  customerOverviews: CustomerOverview[] | undefined,
  filter: string,
  showInactive: boolean
) => CustomerOverview[] = (customerOverviews = [], filter, showInactive) => {
  const {isAccessNumberOverview, isAccessNumberRangeOverview, isReservedOaOverview, isRbmOverview, isGenericOverview} =
    useProductOverviewTypes();
  let filteredCustomerOverviews: CustomerOverview[] | undefined = [...customerOverviews];
  if (!showInactive) {
    filteredCustomerOverviews = filteredCustomerOverviews
      ?.map(
        ({productOverviews, ...customerOverview}) =>
          ({
            productOverviews: productOverviews.filter(isActive),
            ...customerOverview,
          } as CustomerOverview)
      )
      .filter(({productOverviews}) => productOverviews && productOverviews.length > 0);
  }
  if (filter?.length > 0) {
    if (isNaN(parseInt(filter))) {
      //  text filter to match with customer name
      filteredCustomerOverviews = filteredCustomerOverviews.filter(
        (c) =>
          (c.name && c.name.toLowerCase().includes(filter.toLowerCase())) ||
          c.productOverviews.some(
            (overview) =>
              (isReservedOaOverview(overview) &&
                ('senderids'.startsWith(filter.toLowerCase()) ||
                  overview.reservedOas?.some((reservedOa) => reservedOa.startsWith(filter.toLowerCase())))) ||
              (isRbmOverview(overview) &&
                ('rbm'.startsWith(filter.toLowerCase()) || overview.rbmAgentOwner.startsWith(filter.toLowerCase()))) ||
              (isGenericOverview(overview) && overview.productTypeId.toLowerCase().startsWith(filter.toLowerCase()))
          )
      );
    } else {
      //  numeric filter to match access numbers
      filteredCustomerOverviews = filteredCustomerOverviews
        ?.map(({productOverviews, ...customerOverview}) => ({
          productOverviews: [
            ...productOverviews.filter(
              (overview) => isAccessNumberOverview(overview) && overview.accessNumber.startsWith(filter)
            ),
            ...productOverviews.filter(
              (overview) => isAccessNumberRangeOverview(overview) && overview.accessNumberRange.from.startsWith(filter)
            ),
          ],
          ...customerOverview,
        }))
        .filter(({productOverviews}) => productOverviews?.length);
    }
  }
  log.debug('customersFilter', filter);
  return filteredCustomerOverviews;
};

export interface CustomerListItemProps {
  customer: CustomerOverview;
  filter: string;
}

export const CustomerListItem: FC<CustomerListItemProps> = ({customer, filter}) => {
  const {isAccessNumberOverview, isAccessNumberRangeOverview, isReservedOaOverview, isRbmOverview, isGenericOverview} =
    useProductOverviewTypes();

  const {getName} = useProductTypes();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  return (
    <ListItem className={'customer-list__item'} id={domId(customer.id, 'customer')} key={customer.id}>
      <Link key={customer.id} to={formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_OVERVIEW__customerId, customer.id)}>
        {customer.name ? (
          <HighlightSpan
            className={classnames('customer-list__item__company-name bold', {
              inactive: false,
            })}
            text={customer.name}
            highlight={filter}
            onlyWordStart={false}
          />
        ) : (
          <div className="customer-list__item__company-name bold missing">missing company name</div>
        )}
      </Link>

      <div className={'customer-list__item__short-numbers'}>
        {[
          ...customer.productOverviews.map((overview) => ({
            ...overview,
            text:
              (isAccessNumberOverview(overview) && overview.accessNumber) ||
              (isAccessNumberRangeOverview(overview) &&
                '[' + overview.accessNumberRange?.from + '-' + overview.accessNumberRange?.to + ']') ||
              (isReservedOaOverview(overview) &&
                overview.reservedOas?.length &&
                overview.reservedOas.length > 0 &&
                `SenderID[${overview.productName ? overview.productName : overview.reservedOas.join(', ')}](${
                  overview.reservedOas.length
                })`) ||
              (isRbmOverview(overview) && 'RBM[' + overview.rbmAgentOwner + ']') ||
              (isGenericOverview(overview) && getName(overview.productTypeId)) ||
              '',
          })),
        ].map(({customerId, contractId, productId, state, text}) => (
          <Link
            key={productId}
            className={classnames({[`${state.toLowerCase() + 'Period'}`]: !isActive({state})})}
            title={state}
            to={formatWithBrand(
              AppRoutes.PROVISIONING_CUSTOMER_SERVICE__customerId_contractId_productId,
              customerId,
              contractId,
              productId
            )}
            onClick={(e: FormEvent) => {
              log.debug('accessNumber click', {customerId, contractId, productId, text});
              e.stopPropagation();
            }}
          >
            <div className="accessNumberOverview">
              <HighlightSpan text={text} highlight={filter} onlyWordStart={true} />
            </div>
          </Link>
        ))}
      </div>
    </ListItem>
  );
};

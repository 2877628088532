import React, {FC, PropsWithChildren} from 'react';

const LoadingFc: FC<PropsWithChildren> = (props) => {
  return (
    <div className="spinner spinner--lg">
      <div className="spinner__blade spinner__blade--0" />
      <div className="spinner__blade spinner__blade--1" />
      <div className="spinner__blade spinner__blade--2" />
      <div className="spinner__blade spinner__blade--3" />
      <div className="spinner__blade spinner__blade--4" />
      <div className="spinner__blade spinner__blade--5" />
      <div className="spinner__blade spinner__blade--6" />
      <div className="spinner__blade spinner__blade--7" />
      <div className="spinner__blade spinner__blade--8" />
      <div className="spinner__blade spinner__blade--9" />
      <div className="spinner__blade spinner__blade--10" />
      <div className="spinner__blade spinner__blade--11" />
      <div className="spinner__blade spinner__blade--12" />
      <div className="spinner__blade spinner__blade--13" />
    </div>
  );
};

export default LoadingFc;

{
  /*<div className='valign-wrapper' style={{padding: '20px', width: '250px'}}>*/
}
{
  /*<div className='preloader-wrapper active'>*/
}
{
  /*<div className='spinner-layer spinner-red-only'>*/
}
{
  /*<div className='circle-clipper left'>*/
}
{
  /*<div className='circle'/>*/
}
{
  /*</div>*/
}
{
  /*<div className='gap-patch'>*/
}
{
  /*<div className='circle'/>*/
}
{
  /*</div>*/
}
{
  /*<div className='circle-clipper right'>*/
}
{
  /*<div className='circle'/>*/
}
{
  /*</div>*/
}
{
  /*</div>*/
}
{
  /*</div>*/
}
{
  /*<div className='center-align center'> loading...</div>*/
}
{
  /*</div>*/
}

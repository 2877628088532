import {gql, useQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import React, {FC, useMemo, useState} from 'react';

import channelsQuery from '../../graphql/query/channels.graphql';
import findContactsQuery from '../../graphql/query/findContacts.graphql';

import {FormStateOptions, useFormState} from '../../hooks/useFormState';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useRoles} from '../../hooks/useRoles';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Channel, ID} from '../../model';
import Loading from '../Loading';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('Notifications', 'INFO');

interface ChannelsQuery {
  channels: Channel[];
}

interface FindContactsQuery {
  findContacts: ID[];
}

export const NotificationsFc: FC = (props) => {
  const formStateOptions: FormStateOptions = {
    isEditing: true,
    useUrlParams: true,
  };
  const formState = useFormState(formStateOptions);

  const {data: {channels: channelOptions} = {}} = useQuery<ChannelsQuery>(gql(channelsQuery));
  const {customerRoles: roleOptions, loading: loadingRoles} = useRoles();
  const {getBrandProductTypes, loading: loadingProductTypes} = useProductTypes();

  const {currentUserBrand} = useUser();

  const productTypesOptions = useMemo(
    () => currentUserBrand && getBrandProductTypes(currentUserBrand?.id),
    [currentUserBrand?.id, getBrandProductTypes]
  );

  const [fetched, setFetched] = useState(false);
  const {
    loading: loadingFindContacts,
    data: {findContacts} = {},
    refetch,
    fetchMore,
    called,
  } = useQuery<FindContactsQuery>(gql(findContactsQuery), {notifyOnNetworkStatusChange: true});

  log.debug('render', {channelOptions, roleOptions, productTypesOptions, findContacts});
  const {channels, roles, productTypes, showInactive} = formState.entity;

  const onFind = () => {
    log.debug('onFind', {channels, roles, productTypes, showInactive});
    setFetched(true);
    refetch({channels, roles, productTypes, showInactive});
  };

  const onSendEmail = () => {
    const concatEmails = findContacts?.reduce((concat, email) => (concat ? `${concat};${email}` : email), '') || '';
    log.debug('onSendEmail', concatEmails);
    window.open('mailto:?subject=Telia Bulk Messaging notification&bcc=' + concatEmails, '_blank');
  };

  return (
    <React.Fragment>
      <PageSubtitle subtitle="Communication notifications" />

      <p>
        Send email to all customers' <b>communication contacts</b> and <b>users</b>.
      </p>

      <section className={'form-group'}>
        <SectionTitle title={'Notification receivers filters'} />

        <FormRow>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId={'channels'}
              label="Contact type"
              options={channelOptions}
              type={FieldTypes.multi}
            />
            <FieldWithFormState
              formState={formState}
              entityFieldId={'roles'}
              label="User role"
              options={roleOptions}
              type={FieldTypes.multi}
            />
          </FormColumn>
        </FormRow>
      </section>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={'productTypes'}
            label="Service Types"
            options={productTypesOptions}
            type={FieldTypes.multi}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={'showInactive'}
            label={'Show inactive'}
            type={FieldTypes.checkbox}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <Button onClick={onFind} text={'Find emails'} />
        </FormColumn>
      </FormRow>

      {fetched && loadingFindContacts ? (
        <Loading />
      ) : (
        fetched &&
        findContacts && (
          <section className={'form-group form-group--padding'}>
            <SectionTitle title={'User and Contact emails'} />

            <List>
              {findContacts.isEmpty() ? (
                <InformationLineFc description={'Your search did not match any email'} />
              ) : (
                <React.Fragment>
                  <InformationLineFc
                    description={`Your search matches ${findContacts.length} emails`}
                    buttons={<Button onClick={onSendEmail} text={'Send email'} />}
                  />
                  {findContacts.map((email, i) => (
                    <ListItem key={i}>
                      <span>{email}</span>
                    </ListItem>
                  ))}
                </React.Fragment>
              )}
            </List>
          </section>
        )
      )}
    </React.Fragment>
  );
};

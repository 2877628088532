import {gql} from '@apollo/client';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';

import smscFragment from '../../graphql/fragment/smsc.graphql';
import saveSmscMutation from '../../graphql/mutation/saveSmsc.graphql';
import smscsQuery from '../../graphql/query/smscs.graphql';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {useCountries} from '../../hooks/useCountries';
import {Entity, FormState, asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useTimezones} from '../../hooks/useTimezones';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, Smsc, SmscNode} from '../../model';
import {MESSAGE_CHANNELS_MANAGE} from '../../permissions';
import Loading from '../Loading';
import AccordionItem from '../common/AccordionItem';
import {AccordionListFc} from '../common/AccordionList';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {Icon} from '../common/Icon';
import {InformationLineFc} from '../common/InformationLine';
import PageSubtitle from '../common/PageSubtitle';
import SectionTitle from '../common/SectionTitle';
import {FieldTypes, FieldWithFormState} from '../common/field';

const log = getLog('Smsc', 'INFO');

const smscsQueryGql = gql(smscsQuery);

export const SmscFc: FC = (props) => {
  const {hasPermission, loadingUser, currentUserBrand} = useUser();
  const {loading: loadingCountries, countries} = useCountries();

  const params = useParams<{smsc: ID}>() as {smsc: ID};
  const useApolloCacheEntityProps: UseApolloCacheEntityProps = {
    fragment: smscFragment,
    watchQuery: smscsQueryGql,
    watchVariables: {
      countryId: currentUserBrand?.countryId,
    },
    entityId: params.smsc,
    newEntity: {
      id: null,
      ...(currentUserBrand ? {countryId: currentUserBrand?.countryId} : {}),
    },
  };
  const formStateOptions = useApolloCacheEntity(useApolloCacheEntityProps);
  const formState = useFormState(formStateOptions);
  const {isEditing, entity, onEdit, onCancel, isCreating} = formState;
  const navigate = useNavigate();
  const saveSmsc = useMutationWrap<{saveSmsc: Smsc}, {smsc: Smsc}>(gql(saveSmscMutation));
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {timezones} = useTimezones();

  const onSave = () => {
    const smsc = formState.entityAs<Smsc>();
    log.debug('onSave', {smsc, isCreating});
    saveSmsc({
      loadingText: 'Saving SMSC...',
      successText: 'SMSC saved',
      variables: {
        smsc,
      },
      update: (proxy, {data}) => {
        const {saveSmsc} = data || {};
        if (!saveSmsc) {
          log.error('Save Smsc mutation did not return the updated Smsc');
          return;
        }
        if (isCreating) {
          log.debug('saveSmsc update. New smsc created', saveSmsc);
          const data = proxy.readQuery<{smscs: Smsc[]}>({
            query: smscsQueryGql,
          });
          const smscs = [...(data?.smscs || []), saveSmsc];
          proxy.writeQuery({query: smscsQueryGql, data: {smscs}});
          navigate(formatWithBrand(AppRoutes.MANAGEMENT_SMSCS__smsc, saveSmsc.id), {replace: true});
        } else {
          log.debug('saveSmsc update. Existing smsc updated', saveSmsc);
        }
      },
    }).then(({data}) => {
      const {saveSmsc} = data || {saveSmsc: undefined};
      log.debug('saveSmscMutate resolved', saveSmsc);
      saveSmsc && formState.onSaved(asEntity(saveSmsc));
    });
  };

  log.debug('render', {params, isEditing, entity, onEdit, onCancel, isCreating});

  const formButtons = !entity ? null : !isEditing ? (
    <div>
      {hasPermission(MESSAGE_CHANNELS_MANAGE) && <Button text={'Edit'} className="" onClick={onEdit} />}
      <Link to={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS)}>
        <Button text={'Back to List'} kind={'cancel'} />
      </Link>
    </div>
  ) : (
    <div>
      {hasPermission(MESSAGE_CHANNELS_MANAGE) && <Button text={'Save'} onClick={onSave} kind={'primary'} />}
      <Button text={'Cancel'} onClick={onCancel} kind={'cancel'} />
    </div>
  );

  return (
    <>
      <PageSubtitle>
        SMSC
        <div className={'inlineBlock marginLeft'}>{formButtons}</div>
      </PageSubtitle>
      {!entity || loadingUser || loadingCountries ? (
        <Loading />
      ) : (
        <>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'id'} label="ID" isDisabled={!isCreating} />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState formState={formState} entityFieldId={'name'} label="Name" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId="countryId"
                label="Country"
                type={FieldTypes.select}
                options={countries}
                isEditing={false}
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'timeZone'}
                label="Timezone"
                type={FieldTypes.select}
                options={timezones}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'description'}
                label="Description"
                type={FieldTypes.textarea}
              />
            </FormColumn>
          </FormRow>

          <SmscNodesFc parentId={'smscNodes'} formState={formState} />

          <HealthConfigFc parentId={'healthConfig'} formState={formState} />

          <SmscConnectionFc parentId={'smscConnection'} formState={formState} />

          <FormRow>
            <FormColumn>{formButtons}</FormColumn>
          </FormRow>
        </>
      )}
    </>
  );
};

interface NestedFormProps {
  parentId: ID;
  formState: FormState;
  title?: string;
}

const SmscNodesFc: FC<NestedFormProps> = ({parentId, formState}) => {
  const smscNodes: SmscNode[] = formState.subEntityAt<SmscNode[]>(parentId) || [];
  const {showModal} = useModal();
  const onCreateSmscNode = () => {
    log.debug('onCreateSmscNode', smscNodes);
    formState.onChange(parentId)([...smscNodes, {enabled: true}] as Entity[]);
  };

  const onDeleteSmscNode = (index: number) => {
    log.debug('onDeleteSmscNode', {index});
    showModal({
      show: true,
      title: 'Delete SMSC Node',
      content: <span>Are you sure you want to delete the SMSC Node?</span>,
      confirmType: 'negative',
      confirmText: 'Delete',
      onConfirm: () => onConfirmDeleteSmscNode(index),
    });
  };
  const onConfirmDeleteSmscNode = (index: number) => {
    log.debug('onConfirmDeleteSmscNode', {index});
    formState.onChange(parentId)(smscNodes.removeIndex(index) as unknown[] as Entity[]);
  };

  log.debug('SmscNodes', {smscNodes});
  return (
    <FormRow>
      <FormColumn>
        <div>
          <br />
          <AccordionListFc
            label={'SMSC Nodes'}
            multipleOpen={true}
            initialAllOpen={false}
            className={'pendingSettlementsList compactSettlementsList noMarginLeft'}
            rightElement={
              !formState.isEditing ? undefined : (
                <Button text={'Create SMSC Node'} onClick={onCreateSmscNode} kind={'normal'} />
              )
            }
          >
            {smscNodes.isEmpty() ? (
              <div className="marginTop">
                <InformationLineFc>No SMSC nodes</InformationLineFc>
              </div>
            ) : (
              smscNodes.map((smscNode, i) => (
                <AccordionItem
                  id={parentId + '.smscNode-' + i}
                  key={parentId + '.smscNode-' + i}
                  header={<span>SMSC Node {smscNode.id}</span>}
                  headerRightElement={
                    !formState.isEditing ? undefined : (
                      <Icon
                        icon={'trash'}
                        onClick={(e) => {
                          log.warn('trach ', e);
                          onDeleteSmscNode(i);
                          e.stopPropagation();
                        }}
                        info={'Delete'}
                        className={'floatRight settlement__adjustment__icon settlement__adjustment__icon--delete'}
                      />
                    )
                  }
                  content={<SmscNodeFc formState={formState} parentId={`${parentId}[${i}]`} key={'smscNode' + i} />}
                  classNames={classnames('hideTransition')}
                />
              ))
            )}
          </AccordionListFc>
        </div>
      </FormColumn>
    </FormRow>
  );
};

const SmscNodeFc: FC<NestedFormProps> = ({parentId, formState}) => {
  return (
    <>
      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.enabled'}
            label="Enabled"
            type={FieldTypes.checkbox}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState formState={formState} entityFieldId={parentId + '.id'} label="Node ID" />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState formState={formState} entityFieldId={parentId + '.name'} label="Name" />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState formState={formState} entityFieldId={parentId + '.host'} label="Host" />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.port'}
            label="Port"
            type={FieldTypes.number}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.comment'}
            label="Comment"
            type={FieldTypes.textarea}
          />
        </FormColumn>
      </FormRow>
    </>
  );
};

const HealthConfigFc: FC<NestedFormProps> = ({parentId, formState}) => {
  return (
    <section className={'form-group'}>
      <SectionTitle title={'Health Config'} />

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.enquireLinkInterval'}
            label="Enquire Link Interval"
            type={FieldTypes.duration}
          />
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.enquireLinkTimeout'}
            label="Enquire Link Timeout"
            type={FieldTypes.duration}
          />
        </FormColumn>
      </FormRow>
    </section>
  );
};

const SmscConnectionFc: FC<NestedFormProps> = ({parentId, formState}) => {
  return (
    <section className={'form-group'}>
      <SectionTitle title={'SMSC Connection'} />

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.connectTimeout'}
            label="Connect Timeout"
            type={FieldTypes.duration}
          />
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.requestTimeout'}
            label="Request Timeout"
            type={FieldTypes.duration}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.bindTimeout'}
            label="Bind Timeout"
            type={FieldTypes.duration}
          />
          <FieldWithFormState
            formState={formState}
            entityFieldId={parentId + '.windowMonitorInterval'}
            label="Window Monitor Interval"
            type={FieldTypes.duration}
          />
        </FormColumn>
      </FormRow>
    </section>
  );
};

export const CUSTOMER_COMPANY_MANAGE = 'CUSTOMER_COMPANY_MANAGE';
export const CUSTOMER_USERS_MANAGE = 'CUSTOMER_USERS_MANAGE';
export const CUSTOMER_CONTRACTS_MANAGE = 'CUSTOMER_CONTRACTS_MANAGE';
export const CUSTOMER_PRODUCTS_MANAGE = 'CUSTOMER_PRODUCTS_MANAGE';
export const CUSTOMER_TECHNICAL_MANAGE = 'CUSTOMER_TECHNICAL_MANAGE';
export const CUSTOMER_SETTLEMENTS_READ = 'CUSTOMER_SETTLEMENTS_READ';
export const CUSTOMER_STATISTICS_REPORT = 'CUSTOMER_STATISTICS_REPORT';
export const CUSTOMER_DOCUMENTS_READ = 'CUSTOMER_DOCUMENTS_READ';
export const CUSTOMER_DOCUMENTS_MANAGE = 'CUSTOMER_DOCUMENTS_MANAGE';

export const PERMISSIONS_MANAGE = 'PERMISSIONS_MANAGE';
export const ROLES_ASSUME = 'ROLES_ASSUME';
export const ROLES_MANAGE = 'ROLES_MANAGE';
export const TELIA_USERS_MANAGE = 'TELIA_USERS_MANAGE';
export const ALL_CUSTOMERS_MANAGE = 'ALL_CUSTOMERS_MANAGE';
export const ALL_CUSTOMERS_READ = 'ALL_CUSTOMERS_READ';
export const MESSAGE_CHANNELS_MANAGE = 'MESSAGE_CHANNELS_MANAGE';
export const SMPP_CONNECTIONS_READ = 'SMPP_CONNECTIONS_READ';

export const SETTLEMENTS_ADJUST = 'SETTLEMENTS_ADJUST';
export const SETTLEMENTS_APPROVE = 'SETTLEMENTS_APPROVE';

export const MANAGE_MESSAGE_STATUS = 'MANAGE_MESSAGE_STATUS';
export const RESERVED_OA_MANAGE = 'RESERVED_OA_MANAGE';
export const PRODUCT_FEATURES_MANAGE = 'PRODUCT_FEATURES_MANAGE';
